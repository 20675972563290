import formsApi from '@/api/formsApi'
// -------------------------------------------------------------------|
// MODULE STATE
// -------------------------------------------------------------------|
const getDefaultState = (stateIn) => {
  stateIn.title = 'Form Builder'
  stateIn.loading = false
  stateIn.loadingSchema = false
  stateIn.isExisiting = false
  stateIn.options = {
    noDefaultSubmitButton: false,
    builder: {}
  }
  stateIn.formType = {
    settings: []
  }
  stateIn.schema = {
    display: 'form',
    path: '',
    components: [],
    properties: {
      documentTypeId: null,
      saveButton: false,
      staleDataCheck: false
    },
    settings: []
  }
  stateIn.submissionError = null
  stateIn.submissionCount = 0
  stateIn.createCopy = false
  return stateIn
}

const state = getDefaultState({})

// -------------------------------------------------------------------|
// GETTERS
// -------------------------------------------------------------------|
const getters = {
  formItems: state => state.forms
}

// -------------------------------------------------------------------|
// ACTIONS
// -------------------------------------------------------------------|
const actions = {
  getCustomComponents ({ dispatch, commit }) {
    commit('SET_LOADING', true)
    formsApi.getCustomComponents().then((res) => {
      commit('SET_CUSTOM_COMPONENTS', res.data)
    }).catch((err) => {
      if (err) {
        dispatch('notifications/showError', { text: `Failed to fetch custom components ${err}` }, { root: true })
      }
    }).finally(() => {
      commit('SET_LOADING', false)
    })
  },
  updateSchema ({ commit }, schema) {
    commit('UPDATE_SCHEMA', schema)
  },
  fetchSchema ({ dispatch, commit }, schemaId) {
    commit('SET_LOADING_SCHEMA', true)
    formsApi.getBuilderSchema(schemaId).then((res) => {
      // res.data.display = 'form'
      commit('UPDATE_SCHEMA', res.data)
      commit('UPDATE_SCHEMA_TYPE', res.data.formType)
      commit('SET_LOADING_SCHEMA', false)
    }).catch((err) => {
      if (err) {
        dispatch('notifications/showError', { text: `Failed to fetch schema # ${schemaId} ${err}` }, { root: true })
        commit('SET_LOADING_SCHEMA', false)
      }
    })
  },
  setExisting ({ commit }, status) {
    commit('SET_IS_EXIST', status)
  },
  saveSchema ({ dispatch, commit, state }) {
    commit('PREPARE_UPSERT')
    commit('SET_LOADING_SCHEMA', true)
    formsApi.upsertSchema(state.schema).then((res) => {
      commit('UPDATE_SCHEMA', res.data)
      commit('SAVE_SCHEMA')
      state.createCopy = false
      dispatch('notifications/showSuccess', { text: `schema #${state.schema._id} saved successfully` }, { root: true })
    }).catch((err) => {
      commit('SUBMIT_SCHEMA_ERROR', err)
      dispatch('notifications/showError', { text: `Failed to save schema ${err}` }, { root: true })
    }).finally(() => {
      commit('SET_LOADING_SCHEMA', false)
    })
  },
  saveSchemaType ({ dispatch, commit, state }) {
    commit('SET_LOADING_SCHEMA', true)
    commit('PREPARE_UPSERT_TYPE')
    formsApi.upsertSchemaType(state.formType).then((res) => {
      commit('UPDATE_SCHEMA_TYPE', res.data)
      dispatch('notifications/showSuccess', { text: `schema type #${state.formType._id} saved successfully` }, { root: true })
    }).catch((err) => {
      commit('SUBMIT_SCHEMA_ERROR', err)
      dispatch('notifications/showError', { text: `Failed to save schema type ${err}` }, { root: true })
    }).finally(() => {
      commit('SET_LOADING_SCHEMA', false)
    })
  },
  createCopy ({ commit }) {
    commit('SET_CREATE_COPY')
  },
  resetBuilder ({ commit }) {
    commit('RESET_SCHEMA_BUILDER')
  }
}

// -------------------------------------------------------------------|
// MUTATIONS
// -------------------------------------------------------------------|
const mutations = {
  SET_LOADING (state, data) {
    state.loading = data
  },
  SET_LOADING_SCHEMA (state, data) {
    state.loadingSchema = data
  },
  SET_CUSTOM_COMPONENTS (state, data) {
    state.options.builder = data
  },
  UPDATE_SCHEMA (state, data) {
    state.schema = data
    state.submissionError = null
  },
  UPDATE_SCHEMA_TYPE (state, data) {
    state.formType = data
  },
  PREPARE_UPSERT (state) {
    if (state.createCopy) {
      state.schema._id = null
      state.schema.path = null
      state.schema.version = 0
    }
  },
  PREPARE_UPSERT_TYPE (state) {
    state.formType.path = state.schema.path
  },
  SET_IS_EXIST (state, status) {
    state.isExisiting = status
  },
  SAVE_SCHEMA (state) {
    state.submissionCount = state.submissionCount + 1
  },
  SET_CREATE_COPY (state, data) {
    state.createCopy = true
  },
  SUBMIT_SCHEMA_ERROR (state, error) {
    state.submissionError = error.response
  },
  RESET_SCHEMA_BUILDER () {
    getDefaultState(state)
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
