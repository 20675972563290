<template>
  <v-card class="ma-3 formio-widget">
    <v-overlay absolute="absolute" :value="showLoading" >
      <v-progress-circular  v-if="showLoading" size="64" indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-alert type = 'warning'
      v-model="showErrors"
    >Form could not be refreshed - using local copy
    </v-alert>
    <v-dialog v-model="confirmDialog"
      hide-overlay
      max-width="440px"
      >
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <!-- <v-card-text>If you choose to refresh, any user entered data will be cleared.</v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmDialog = false">No</v-btn>
          <v-btn color="red darken-1" text @click="clearForm">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="submitting"
        persistent
        max-width="400"
      >
      <v-card
        color="primary"
        light
      >
        <v-card-title class="headline">
          Submitting form...
        </v-card-title>
        <v-card-text>
          Please do not close this page until submission completes.
          <v-progress-linear  v-if="submitting" indeterminate color="white"></v-progress-linear>
        </v-card-text>
        <v-btn text @click="submitting = false">Close</v-btn>
      </v-card>
    </v-dialog>
    <v-card-title v-if="formSchemaData">
      <v-col>{{ formSchemaData.name }}</v-col>
      <v-col>
        <v-btn v-if="showButton" class = "primary float-right"
          @click="confirmDialog=true">
          Clear form
        </v-btn>
      </v-col>
      <v-btn class="mr-12 mx-auto" :hidden="!formSchemaData.properties.saveButton" @click="storeEvent()">
        <v-overlay absolute="absolute" :value="saving">
          <v-progress-circular  v-if="saving" indeterminate color="primary"></v-progress-circular>
        </v-overlay>
        Save
      </v-btn>
    </v-card-title>
    <v-divider class="mt-0"></v-divider>
    <v-card-text v-if="dataReady" class = "blackCardText">
     <formio  url="" ref="formioForm" :form="formSchemaData" :submission="formSubmission" :options="formOptions" @initialized="readyEvent" @render="renderEvent" @submit="submitEvent" @change="changeEvent"></formio>
    </v-card-text>
  </v-card>
</template>

<script>
import { Form } from 'vue-formio'
import { mapGetters } from 'vuex'
import * as moment from 'moment'
export default {
  name: 'FormWidget',
  props: ['formSchemaData', 'formErrors', 'formOptions', 'storeIncompleteSubmission', 'showClearButton'],
  components: {
    formio: Form
  },
  data: () => ({
    active: false,
    dataReady: false,
    saving: false,
    submitting: false,
    confirmDialog: false,
    formReady: true
  }),
  beforeMount () {
    this.active = true
  },
  methods: {
    submitEvent (data) {
      if (!this.prefillData.isValid && this.formSchemaData.properties.staleDataCheck) {
        alert('the form is not valid, please use action button at top of page')
        return
      }
      this.submitting = true
      this.$store.dispatch('formHandler/saveForm', data)
    },
    clearForm () {
      this.$store.dispatch('formHandler/refreshFormData', { id: this.$route.params.id, query: this.$route.query })
      this.confirmDialog = false
    },
    renderEvent (e) {
      this.disableChoiceAutoFill()
    },
    readyEvent (e) {
      this.formReady = true
    },
    disableChoiceAutoFill () {
      // make sure autocmplete disabled for all choice js inputs
      // otherwise browser autofill dropdown can be displayed and selected
      var inputs = document.querySelectorAll('.choices__input')
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].autocomplete = 'disable_DocManager_choices'
      }
    },
    changeEvent (data, changes) {
      if (this.isDataReady) {
        if (this.storeIncompleteSubmission && changes.changed) {
          const query = this.$route.query
          this.$store.dispatch('formHandler/storeForm', { query: query })
        }
      }
    },
    async storeEvent (data) {
      if (this.isDataReady && this.storeIncompleteSubmission && !this.saving) {
        this.saving = true
        const query = this.$route.query
        var expiryDate = moment(new Date()).add(7, 'd').toDate()
        await this.$store.dispatch('formHandler/storeForm', { expiryDate: expiryDate, query: query, storeRemote: true, showSuccess: true })
      }
      this.saving = false
    }
  },
  watch: {
    submissionId (newStatus, oldStatus) {
      if (newStatus > oldStatus) {
        this.submitting = false
        this.currentForm.emit('submitDone')
      }
    },
    formSchemaData (newStatus, oldStatus) {
      if (this.isDataReady) {
        this.dataReady = this.isDataReady
      }
    },
    formLoading  (newStatus, oldStatus) {
      if (this.isDataReady) {
        this.dataReady = this.isDataReady
      }
    }
  },
  computed: {
    ...mapGetters({
      formLoading: 'formHandler/formLoading',
      prefillData: 'formHandler/prefillData'
    }),
    formSubmission () {
      return this.$store.state.formHandler.submission
    },
    currentForm (e) {
      return e.$refs.formioForm.formio
    },
    submissionId () {
      return this.$store.state.formHandler.submissionCount
    },
    schemaLoading () {
      return this.$store.state.formHandler.formLoading
    },
    showErrors () {
      if (this.formErrors) {
        return this.formErrors
      }
      return false
    },
    isDataReady () {
      return !this.formLoading && this.formSchemaData
    },
    showLoading () {
      return this.formLoading || !this.formReady
    },
    showButton () {
      if (this.showClearButton === undefined) {
        return true
      }
      return this.showClearButton
    }
    // ...mapGetters({
    //   formSchema: 'formHandler/formSchema'
    // })
  }
}
</script>
