
const actions = {
  refresh () {
    location.reload()
  }
}

const module = {
  namespaced: true,
  actions
}

export default module
