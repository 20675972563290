
const setAuthorizationHeader = function (config) {
  config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token')
  config.headers.user_name = localStorage.getItem('user_name')
  config.headers.user_email = localStorage.getItem('user_email')
  config.headers.user_id = localStorage.getItem('user_id')
  config.headers.employee_id = localStorage.getItem('employee_id')
}

export default {
  setAuthorizationHeader
}
