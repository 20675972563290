import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#000037',
        anchor: '#14abd3',
        mauve: '#ADADDC'
        // teal: '#14abd3',
        // searchText: '#14abd3'
        // primary: '#14abd3',
        // anchor: '#005261',
        // mauve: '#ADADDC',
        // teal: '#005261',
        // searchText: '#005261'
      },
      dark: {
        primary: '#000037'
      }
    },
    light: true
  }
}

// const optsTest = {
//   theme: {
//     themes: {
//       dark: {
//         primary: '#14abd3'
//       }
//     }
//   }
// }
export default new Vuetify(opts)
