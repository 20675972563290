import idbs from '@/api/indexedDBService'
import formsApi from '@/api/formsApi'
import { find, reject } from 'lodash'
import * as moment from 'moment'
// -------------------------------------------------------------------|
// MODULE STATE
// -------------------------------------------------------------------|
const getDefaultState = () => {
  return {
    title: 'Incomplete submissions list',
    loading: true,
    submissions: [],
    schemas: []
  }
}

const state = getDefaultState()

// -------------------------------------------------------------------|
// GETTERS
// -------------------------------------------------------------------|
const getters = {
  submissionItems: state => state.submissions
}

// -------------------------------------------------------------------|
// ACTIONS
// -------------------------------------------------------------------|
const actions = {
  async getAvailableSubmissions ({ commit, dispatch, state, rootState }) {
    commit('SET_LOADING', true)
    // first clean up any expired ones
    dispatch('deleteExpiredSubmissions')
    await dispatch('fetchAllFromServer')
    idbs.getAllStoredSchemas().then((res) => {
      commit('SET_ALL_STORED_SCHEMAS', res)
      idbs.getAllStoredIncompletes().then((submissions) => {
        submissions.forEach(submission => {
          var schema = findSchema(submission.formId)
          submission.schema = schema
        })
        commit('SET_INCOMPLETE_SUBMISSIONS_LIST', submissions)
      }).finally(() => {
        commit('SET_LOADING', false)
      })
    })
  },
  deleteExpiredSubmissions ({ dispatch, commit }) {
    idbs.getAllStoredIncompletes().then((submissions) => {
      submissions.forEach(submission => {
        // add 100 days to expiry for debugging purposes
        var expiryPlus100 = moment(submission.expiryDate).add(100, 'd').toDate()
        if (expiryPlus100 < new Date()) {
          dispatch('deleteIncompleteSubmission', submission.id)
        }
      })
      commit('SET_INCOMPLETE_SUBMISSIONS_LIST', submissions)
    })
  },
  async deleteIncompleteSubmission ({ commit }, id) {
    var getStored = await idbs.getStoredIncomplete(id)
    if (getStored) {
      await formsApi.deleteIncompleteSubmission({ id: getStored.id })
    }
    idbs.removeIncompleteFromStorage(id)
    commit('REMOVE_INCOMPLETE_SUBMISSION', id)
  },
  async sendToServer ({ commit }, params) {
    await formsApi.saveIncompleteSubmission(params)
  },
  async fetchAllFromServer ({ rootState }) {
    if (rootState.appOnline) {
      var incompleteServerSubmissions = await formsApi.getIncompleteSubmissions()
      for (const s of incompleteServerSubmissions.data) {
        var existingIncompleteSubmission = await idbs.getStoredIncomplete(s.id)
        if (!existingIncompleteSubmission) {
          await idbs.storeIncompleteSubmissionToStorage(s.formId, s.submission, s.formActions, s.id, s.expiryDate, null, true)
        }
      }
    }
  }
}

const findSchema = schemaId => {
  return find(state.schemas, { id: schemaId })
}

// -------------------------------------------------------------------|
// MUTATIONS
// -------------------------------------------------------------------|
const mutations = {
  SET_LOADING (state, data) {
    state.loading = data
  },
  SET_INCOMPLETE_SUBMISSIONS_LIST (state, data) {
    state.submissions = data
  },
  SET_ALL_STORED_SCHEMAS (state, data) {
    state.schemas = data
  },
  REMOVE_INCOMPLETE_SUBMISSION (state, data) {
    const cleanState = reject(state.submissions, { id: data })
    state.submissions = cleanState
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
