export default [
  {
    type: 'checkbox',
    label: 'Resize images',
    key: 'resizeImages',
    defaultValue: true,
    input: true,
    weight: 1,
    tooltip: 'Check this to resize images before upload.'
  },
  {
    type: 'number',
    label: 'Resize Height',
    key: 'resizeHeight',
    input: true,
    weight: 2,
    defaultValue: 600,
    placeholder: 'Enter resize Height',
    tooltip: 'Enter the Enter resize height of image.'
  },
  {
    type: 'number',
    label: 'Resize Width',
    key: 'resizeWidth',
    input: true,
    weight: 2,
    defaultValue: 800,
    placeholder: 'Enter resize Width',
    tooltip: 'Enter the Enter resize width of image.'
  },
  {
    type: 'number',
    label: 'Resize Quality',
    key: 'resizeQuality',
    input: true,
    weight: 2,
    defaultValue: 0.7,
    placeholder: 'Enter resize quality',
    tooltip: 'Enter the Enter decimal resize quality of image. (0 -> 1)'
  }
]
