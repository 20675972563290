import { Formio } from 'vue-formio'
import router from '@/router'
let API_URL = window.__config.API_URL || 'https://formsapi-int.myhavenweb.com/'
if (process.env.VUE_APP_API) {
  API_URL = process.env.VUE_APP_API
}

Formio.projectUrl = API_URL
Formio.baseUrl = API_URL

// authPlugIn required for nested forms and any other api calls made directly from formio components
var authPlugin = {
  priority: 0,
  preRequest: function (requestArgs) {
    // pass through queryParams
    const path = router.history.current.fullPath
    // replace tokens in url
    requestArgs.url = requestArgs.url?.replace('{server_name}', API_URL)
    const indexOfParams = path.indexOf('?')
    if (indexOfParams > 0) {
      requestArgs.url += path.substring(indexOfParams)
    }
    if (requestArgs.opts) {
      requestArgs.opts.header = null
      requestArgs.opts.headers = {
        authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json; charset=utf-8'
      }
    }
    return new Promise(function (resolve, reject) {
      resolve()
    })
  }
}
const formioAuthPlugin = {
  install: () => {
    Formio.registerPlugin(authPlugin, 'authPlugin')
  }
}
export default formioAuthPlugin
