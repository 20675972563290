import formsApi from '@/api/formsApi'
import idbs from '@/api/indexedDBService'
import { dataHelpers } from '@/store/helpers'
import router from '@/router'
// -------------------------------------------------------------------|
// MODULE STATE
// -------------------------------------------------------------------|
const getDefaultState = (stateIn) => {
  stateIn.title = 'Form actions'
  stateIn.loading = true
  stateIn.formId = 'empty'
  setDefaultData(stateIn)

  stateIn.constants = {
    loading: true,
    data: [{
      name: 'form_action',
      actions: [
        'fetch_documents',
        'fetch_employee_documents',
        'fetch_child_documents'
      ]
    }]
  }
  stateIn.errors = []
  return stateIn
}

const setDefaultData = (stateIn) => {
  stateIn.data = [
    {
      formId: null,
      documents: [
        {
          documentId: null,
          documentResponse: {},
          name: null,
          url: null,
          loading: false
        }
      ]
    }
  ]
}

const state = getDefaultState({})

// -------------------------------------------------------------------|
// GETTERS
// -------------------------------------------------------------------|
const getters = {
  formActions: state => {
    return state
  },
  actionTriggers: state => {
    const result = state.constants.data.map(a => a.name)
    return result
  },
  actionTriggersFull: state => {
    const result = state.constants.data
    return result
  }
}

// -------------------------------------------------------------------|
// ACTIONS
// -------------------------------------------------------------------|
const actions = {
  getActionConstants ({ commit }) {
    formsApi.getActionConstants().then((res) => {
      commit('SET_ACTION_CONSTANTS', res.data)
      commit('SET_ACTION_CONSTANTS_LOADING', false)
    })
  },
  getFormActions ({ commit, dispatch, rootState }, params) {
    commit('RESET')
    commit('SET_ACTIONS_LOADING', true)
    commit('SET_FORM_ID', params.formId)
    formsApi.getFormActions(params.formId, params.userId, params.ndisNumber, params.serviceId).then((res) => {
      dispatch('setActions', res.data)
      commit('SET_ACTIONS_LOADING', false)
    }).catch(error => {
      var message = dataHelpers.nullishOperator(error?.response?.data, error)
      commit('SET_ACTION_ERRORS', 'cannot fetch action data: ' + message)
      commit('SET_ACTIONS_LOADING', false)
    })
  },
  getIncompleteActions ({ commit, dispatch }, params) {
    commit('SET_ACTIONS_LOADING', true)
    commit('SET_FORM_ID', params.id)
    idbs.getStoredIncomplete(params.id).then((res) => {
      if (res && res !== 'undefined') {
        if (res.formActions) {
          dispatch('setActions', res.formActions)
        }
      }
    }).finally(() => {
      commit('SET_ACTIONS_LOADING', false)
    })
  },
  getSubmissionActions ({ commit, dispatch, rootState }, id) {
    commit('RESET')
    commit('SET_ACTIONS_LOADING', true)
    formsApi.getCompletion(id).then((res) => {
      // dispatch('setActions', res.data)
      commit('SET_ACTIONS', res.data)
      commit('SET_ACTIONS_LOADING', false)
    }).catch(error => {
      var message = dataHelpers.nullishOperator(error?.response?.data, error)
      commit('SET_ACTION_ERRORS', 'cannot fetch action data: ' + message)
      commit('SET_ACTIONS_LOADING', false)
    })
  },
  setActions ({ commit, dispatch }, data) {
    commit('SET_ACTIONS', data)
    const query = router.currentRoute.query
    dispatch('formHandler/storeForm', { query: query }, { root: true })
  }
}

// -------------------------------------------------------------------|
// MUTATIONS
// -------------------------------------------------------------------|
const mutations = {
  SET_ACTIONS_LOADING (state, data) {
    state.loading = data
  },
  SET_FORM_ID (state, formId) {
    state.formId = formId
  },
  SET_ACTIONS (state, data) {
    data.forEach(element => {
      if (element.documents) {
        element.documents.forEach(doc => {
          doc.loading = false
        })
      }
    })
    state.data = data
  },
  SET_ACTION_CONSTANTS (state, data) {
    state.constants.data = data.actionsTypes
  },
  SET_ACTION_CONSTANTS_LOADING (state, data) {
    state.constants.loading = data
  },
  SET_ACTION_ERRORS (state, data) {
    state.errors = data
  },
  RESET (state) {
    getDefaultState(state)
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
