import formsApi from '@/api/formsApi'
import idbs from '@/api/indexedDBService'

// -------------------------------------------------------------------|
// MODULE STATE
// -------------------------------------------------------------------|
const getDefaultState = () => {
  return {
    title: 'Available Forms List',
    loading: true,
    forms: [],
    filter: {
      searchString: null
    },
    pagerTotal: 0,
    pagination: {
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
      sortBy: ['name']
    }
  }
}

const state = getDefaultState()

// -------------------------------------------------------------------|
// GETTERS
// -------------------------------------------------------------------|
const getters = {
  formItems: state => state.forms
}

// -------------------------------------------------------------------|
// ACTIONS
// -------------------------------------------------------------------|
const actions = {
  getAvailableForms ({ commit, state, rootState }) {
    idbs.getStoredFormsList().then((res) => {
      commit('SET_FORMS_LIST', res.forms)
    })
    if (rootState.appOnline) {
      commit('SET_LOADING', true)
      formsApi.getList(state.filter, state.pagination).then((res) => {
        commit('SET_FORMS_LIST', res.data)
        idbs.saveFormListToStorage(res.data)
      }).catch(error => {
        // attempt to get from db
        if (error) {
          idbs.getStoredFormsList().then((res) => {
            commit('SET_FORMS_LIST', res.forms)
          })
        }
      }).finally(() => {
        commit('SET_LOADING', false)
      })
    }
  },
  resetAudiences ({ commit }) {
    commit('RESET_FORMS_LIST')
  }
}

// -------------------------------------------------------------------|
// MUTATIONS
// -------------------------------------------------------------------|
const mutations = {
  SET_LOADING (state, data) {
    state.loading = data
  },
  SET_FORMS_LIST (state, data) {
    state.forms = data
  },
  RESET_AUDIENCE_LIST (state, data) {
    const newstate = getDefaultState()
    state.pagination = newstate.pagination
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
