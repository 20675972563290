<template>
  <v-row>
    <v-col>
        <v-menu
        v-model="menuStart"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="From date"
            prepend-icon="mdi-calendar"
            readonly
            clearable
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          @input="menuStart = false"
        ></v-date-picker>
      </v-menu>
      </v-col>
        <v-col>
        <v-menu
        v-model="menuFinish"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="finishDate"
            label="To date"
            prepend-icon="mdi-calendar"
            readonly
            clearable
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="finishDate"
          @input="menuFinish = false"
          :min="startDate"
          :click="$emit('change-date', { startDate: startDate, finishDate: finishDate })"
        ></v-date-picker>
      </v-menu>
      </v-col>
    </v-row>
</template>

<script>
export default {
  // module name
  name: 'DateRange',
  props: ['fromDate', 'toDate'],
  components: {
  },
  data: () => ({
    menuStart: false,
    menuFinish: false,
    startDate: null,
    finishDate: null
  }),
  mounted () {
    if (this.fromDate) {
      this.startDate = this.fromDate
    }
    if (this.toDate) {
      this.finishDate = this.toDate
    }
  },
  computed: {
  },
  // functions the UI requires to change the data in the store
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
