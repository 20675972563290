<template>
  <div class="pa-2">
    <v-card>
      <v-tabs
        background-color="teal"
        slider-color="primary"
        v-model="tabmodel" show-arrows
        color="white"
      >
        <v-tab
          v-for="(tab, i) in tabs"
          :key="i"
          :href="`#tab-${i}`"
        >
          {{tab.title}}
        </v-tab>
      </v-tabs>
      <v-tabs-items touchless v-model="tabmodel">
        <v-tab-item
          v-for="(tabcontent, i) in tabs"
          :key="i"
          :value="`tab-${i}`"
        >
          <component :is="tabcontent.component"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import FormList from '@/components/forms/FormList'
import SavedFormList from '@/components/forms/SavedFormList'
import FormSchemaList from '@/components/forms/FormSchemaList'

export default {
  name: 'Home',
  data: () => ({
    tabmodel: 'tab-0',
    tabs: [
      { title: 'Submit New Form', visible: true, component: FormList },
      { title: 'Saved Forms', visible: true, component: SavedFormList },
      { title: 'Form Designer', visible: true, component: FormSchemaList }
    ]
  }),
  components: {
  }
}
</script>
