import { UserManager, WebStorageStateStore } from 'oidc-client'
import router from '@/router'
import { dataHelpers } from '@/store/helpers'
import { isJwtExpired } from 'jwt-check-expiration'
// import idbs from '@/api/indexedDBService'
const settings = {
  authority: process.env.VUE_APP_AUTH_URI ?? window.__config.AUTH_URI,
  client_id: process.env.VUE_APP_AUTH_CLIENT_ID ?? window.__config.AUTH_CLIENT_ID,
  redirect_uri: window.__config.AUTH_REDIRECT_URI,
  silent_redirect_uri: window.__config.AUTH_SILENT_REDIRECT,
  post_logout_redirect_uri: window.__config.AUTH_LOGOUT_REDIRECT,
  response_type: 'id_token token',
  scope: 'openid profile roles documentmanager_readwrite',
  acr_values: 'tenant:1',
  accessTokenExpiringNotificationTime: 4,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  userStore: new WebStorageStateStore({ store: window.localStorage })
}

// oidc connection manager
const manager = new UserManager(settings)

// when there is a silent renewing error
// Anti pattern here, but this out of flow with vuex and relies on OIDC
manager.events.addSilentRenewError(function (error) {
  // eslint-disable-next-line
  console.error('error while renewing the access token', error)
  state.authenticated = false
  state.user = false
})

// Anti pattern here, but this out of flow with vuex and relies on OIDC
manager.events.addAccessTokenExpired(function () {
  // eslint-disable-next-line
  console.error('access token expired')
  if (state.urlLogin !== true) {
    state.authenticated = false
    state.user = false
  }
})

const state = {
  title: 'Auth',
  authenticated: false,
  pending: false,
  user: false,
  urlLogin: false
}

const getters = {
  loggedin: state => state.authenticated,
  access_token: state => state.user.access_token,
  userFullName: state => {
    if (state.user.profile) {
      return `${state.user.profile.given_name} ${state.user.profile.family_name}`
    }
  },
  userDetails: state => state.user.profile,
  urlLogin: state => state.urlLogin
}

const actions = {
  // Starts the oauth signin process
  login ({ commit }, creds) {
    manager
      .signinRedirect()
      .catch(function (error) {
        // eslint-disable-next-line
        console.error('error while logging in:', error)
      })
  },

  // Ends users outh session and logs out
  logout ({ commit }) {
    commit('SET_LOGOUT')
    manager.signoutRedirect()
  },

  // Checks login -> Fired on load/refresh
  checkLogin ({ commit, dispatch }) {
    manager.getUser().then(function (user) {
      if (user) {
        if (validateRole(user)) {
          setLocalStorage(user, user.profile.sub)

          commit('SET_LOGIN_SUCCESS', user)
          manager.startSilentRenew()
        } else {
          commit('SET_LOGOUT')
          localStorage.removeItem('access_token')
          manager.signoutRedirect()
        }
      } else {
        commit('SET_LOGOUT')
        localStorage.removeItem('access_token')
        localStorage.removeItem('user_name')
        localStorage.removeItem('user_email')
        localStorage.removeItem('user_id')
        localStorage.removeItem('employee_id')
      }
      // Gets rid of stale sessions from localStorage
      manager.clearStaleState().catch(function (error) {
        // eslint-disable-next-line
        console.error('error while trying to clear state:', error)
      })
    })
  },

  signinSilent () {
    return manager.signinSilent().then(user => {
      if (validateRole(user)) {
        setLocalStorage(user, user.profile.sub)
      }
    })
  },

  async setAuthFromUrl ({ commit, dispatch, state }, query) {
    var expired = isJwtExpired(query.token)
    if (expired) {
      commit('SET_LOGOUT')
      localStorage.removeItem('access_token')
      manager.signoutRedirect()
      return
    }
    commit('SET_URL_LOGIN', true)
    var profile = {
      given_name: query.user_name,
      family_name: '',
      email: query.user_email,
      sub: query.user_id
    }
    var user = {
      access_token: query.token,
      profile: profile
    }
    setLocalStorage(user, query.employee_id, query.sync_id)
    commit('SET_LOGIN_SUCCESS', user)
    manager.stopSilentRenew()

    var type = query.type
    var id = query.id

    var externalIdentifer = dataHelpers.nullishOperator(query.external_identifier_key, '')
    if (type === 'complete') {
      router.replace(`/forms/complete/${id}?externalIdentifierKey=${externalIdentifer}`)
    }
    if (type === 'submission') {
      router.replace(`/forms/submission/${id}?externalIdentifierKey=${externalIdentifer}`)
    }
    if (type === 'form') {
      var userId = dataHelpers.nullishOperator(query.employee_id, '')
      var compositeKey = dataHelpers.getIncompleteSubmissionKey(id,
        {
          serviceId: query.service_id,
          ndisNumber: query.ndis_number,
          userId: userId,
          serviceListIds: query.service_list_ids,
          careTypeId: query.care_type_id
        })

      var storedIncompleteSubmission = await dispatch('formHandler/getIncompleteSubmission', { id: compositeKey }, { root: true })
      var resume = (storedIncompleteSubmission && (new Date(storedIncompleteSubmission.expiryDate) > new Date()))

      if (resume) {
        router.replace(`/forms/resume/${compositeKey}&externalIdentifierKey=${externalIdentifer}`)
      } else {
        router.replace(`/forms/add/${compositeKey}&externalIdentifierKey=${externalIdentifer}`)
      }
    }
  }
}

function setLocalStorage (user, employeeId, syncId) {
  localStorage.setItem('access_token', user.access_token)
  localStorage.setItem('user_name', `${user.profile.given_name} ${user.profile.family_name}`)
  localStorage.setItem('user_email', user.profile.email)
  localStorage.setItem('user_id', user.profile.sub)
  localStorage.setItem('employee_id', employeeId)
  if (syncId) {
    localStorage.setItem('sync_id', syncId)
  }
}
// function hasPermission (userAcl, requestedPermissionAcl) {
//   const data = atob(userAcl)
//   const array = Uint8Array.from(data, b => b.charCodeAt(0))
//   const arrayPos = Math.floor((requestedPermissionAcl - 1) / 8)
//   const floor = arrayPos * 8
//   if (array.length < arrayPos) {
//     return false
//   }
//   const bitVal = 1 << (requestedPermissionAcl - floor - 1)
//   const arrayVal = array[arrayPos]
//   const res = arrayVal & bitVal
//   // console.log('hasPermission', userAcl, bitVal, array, arrayPos, array[arrayPos])
//   return res >= bitVal
// }
function validateRole (user) {
  // console.log('has permission', dataHelpers.containsPermissionInHash(user.profile.acl, 1612))
  if (!window.__config.REQUIRED_ROLE) {
    return true
  }

  if (!user.profile.role) {
    return false
  }

  if (user.profile.role === window.__config.REQUIRED_ROLE) {
    return true
  }

  if (Array.isArray(user.profile.role)) {
    return user.profile.role.some(r => window.__config.REQUIRED_ROLE.indexOf(r) >= 0)
  }
  return window.__config.REQUIRED_ROLE.some(r => r === user.profile.role)
}

const mutations = {
  SET_LOGIN_PENDING (state) {
    state.pending = true
  },
  SET_LOGIN_SUCCESS (state, user) {
    state.authenticated = true
    state.pending = false
    state.user = user
  },
  SET_URL_LOGIN (state, value) {
    state.urlLogin = value
  },
  SET_LOGOUT (state) {
    state.authenticated = false
    state.user = false
    state.userDetails = false
    state.urlLogin = false
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
