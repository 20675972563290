import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Search from '../views/search/Search.vue'
import GenerateLink from '../views/utils/GenerateLink.vue'
import ClientAccess from '../views/utils/ClientAccess.vue'
import FormsAdd from '../views/forms/FormsAdd.vue'
import FormsResume from '../views/forms/FormsResume.vue'
import FormsSubmission from '../views/forms/FormsSubmission.vue'
import FormsSubmissionSuccess from '../views/forms/FormsSubmissionSuccess.vue'
import FormsSubmissionOffline from '../views/forms/FormsSubmissionOffline.vue'
import FormsComplete from '../views/forms/FormsComplete.vue'
import SchemaAdd from '../views/schema/SchemaAdd.vue'
import SchemaEdit from '../views/schema/SchemaEdit.vue'
import SchemaCopy from '../views/schema/SchemaCopy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/forms/add/:id',
    name: 'Add form',
    component: FormsAdd
  },
  {
    path: '/forms/resume/:id',
    name: 'Resume form',
    component: FormsResume
  },
  {
    path: '/forms/submission/:id',
    name: 'Form submission',
    component: FormsSubmission
  },
  {
    path: '/forms/complete/:id',
    name: 'Form complete',
    component: FormsComplete
  },
  {
    path: '/forms/submission/offline/:id',
    name: 'Form Submission Offline',
    component: FormsSubmissionOffline
  },
  {
    path: '/forms/submissionsuccess/:id',
    name: 'Form Submission Success',
    component: FormsSubmissionSuccess
  },
  {
    path: '/schema/add',
    name: 'Add New Schema',
    component: SchemaAdd
  },
  {
    path: '/schema/edit/:id',
    name: 'Edit Schema',
    component: SchemaEdit
  },
  {
    path: '/schema/copy/:id',
    name: 'Copy Schema',
    component: SchemaCopy
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/utils',
    name: 'Generate link',
    component: GenerateLink
  },
  {
    path: '/clientaccess',
    name: 'Client access',
    component: ClientAccess
  },
  {
    path: '/about',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
