<template>
  <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      :multi-line="snackbar.mode === 'multi-line'"
      :vertical="snackbar.mode === 'vertical'"
    >
      <div :align="snackbar.align">{{snackbar.text}}
        <v-progress-circular
          :size="30"
          v-if="snackbar.loader"
          color="white"
          indeterminate
        ></v-progress-circular>
        <v-btn
          v-if="snackbar.action"
          dark
          text
          @click="executeAction"
        >
          {{snackbar.actionText}}
        </v-btn>
        <v-btn
          v-if="snackbar.closeBtn"
          dark
          text
          @click="closeSnackbar"
        >
          Close
        </v-btn>
      </div>
    </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  // module name
  name: 'Notifications',
  // data used locally in the component
  // data that requires manipulation before presenting in the template
  computed: {
    ...mapGetters({
      snackbar: 'notifications/snackbar'
    })
  },
  // functions the UI requires to change the data in the store
  methods: {
    closeSnackbar () {
      this.$store.dispatch('notifications/close')
    },
    executeAction () {
      this.$store.dispatch(this.snackbar.action)
      this.closeSnackbar()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
