// use @/api in real world
import API from '@/api'
// import Axios from 'axios'

const endpoints = {
  getSubmissions: '/Search/GetPagedSubmissionIds',
  getSchemaHistory: '/FormMetadata/ListFormsHistory',
  getFormLinkAuth: '/Link',
  getTeamMembers: '/Team',
  addClientAccess: '/Team/ClientAccess'
}

const getSubmissions = (search, options) => {
  // var reqArgs = { params: { search: search } }
  var data = {
    search: {
      formName: search.form, employeeId: search.employeeId, textId: search.textId, ndisNumber: search.ndisNumber, dateFrom: search.startDate, dateTo: search.finishDate
    },
    paging: options
  }

  return API.post(endpoints.getSubmissions, data)
}

const getSchemaHistory = () => {
  return API.get(endpoints.getSchemaHistory)
}

const getFormLinkAuth = (clientUri, formId) => {
  var reqArgs = { params: { clientUri: clientUri, formId: formId } }
  return API.get(endpoints.getFormLinkAuth, reqArgs)
}

const getTeamMembers = () => {
  return API.get(endpoints.getTeamMembers)
}
const addClientAccess = (employeeId, submissionId) => {
  var reqArgs = { params: { employeeId: employeeId, submissionId: submissionId } }
  return API.get(endpoints.addClientAccess, reqArgs)
}

export default {
  getSubmissions,
  getSchemaHistory,
  getFormLinkAuth,
  getTeamMembers,
  addClientAccess
}
