<template>
  <section>
    <div v-if="!onlineWarning">
      <form-widget :formSchemaData="formSchema" :formOptions="formOptions" :storeIncompleteSubmission="false"></form-widget>
    </div>
    <form-online-dialog :visible="onlineWarning"></form-online-dialog>
  </section>
</template>

<script>
import FormWidget from '@/components/forms/FormWidget'
import FormOnlineDialog from '@/components/forms/FormOnlineDialog'
import router from '@/router'
import { mapGetters } from 'vuex'
export default {
  name: 'FormsSubmission',
  data: () => ({
    onlineWarning: false
  }),
  mounted () {
    // const params = this.$route.params
    const query = this.$route.query
    this.$store.dispatch('formHandler/getStoredSchemas')
    this.$store.dispatch('formHandler/setOptions', { readOnly: true })
    this.$store.dispatch('formHandler/setExisting', true)
    this.$store.dispatch('formHandler/setExternalIdentifierKey', query.externalIdentifierKey)
    if (this.isOnline) {
      router.replace('/home')
    }
  },
  beforeDestroy () {
    // this.$store.dispatch('formHandler/resetForm')
  },
  watch: {
    isOnline (newStatus, oldStatus) {
      if (newStatus === true && oldStatus === false) {
        this.onlineWarning = true
      }
    }
  },
  computed: {
    ...mapGetters({
      formSchema: 'formHandler/formSchema',
      formOptions: 'formHandler/formOptions'
    }),
    isOnline () {
      return this.$store.state.appOnline
    }
  },
  components: {
    FormWidget,
    FormOnlineDialog
  }
}
</script>

<style>

</style>
