<template>
  <v-app id="inspire">
    <section v-if="!loggedin">
      <login-pane></login-pane>
    </section>
    <section class="fill-height" v-if="loggedin">
      <v-navigation-drawer v-if="showDrawer"
        v-model="drawer"
        :disable-resize-watcher="true"
        app
      >
        <v-list class="pa-0">
          <v-list-item>
            <v-list-item-avatar>
              <img src="https://randomuser.me/api/portraits/lego/1.jpg">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ username }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense>
          <v-divider></v-divider>
          <template v-for="(item, i) in nav">
            <v-list-item router-link :to="{path: item.route}" :key="i">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-divider></v-divider>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar
        app
        color="primary"
        light
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{$route.name}}</v-toolbar-title><v-chip v-if="!isOnline" class="ml-3" small color="error">APP OFFLINE</v-chip>
      </v-app-bar>

      <v-main class="fill-height">
        <v-container class="pa-0 fill-height" fluid>
          <div class="main-container fill-height pb-0 pt-2">
            <v-slide-y-transition hide-on-leave>
              <router-view></router-view>
            </v-slide-y-transition>
            <notifications></notifications>
          </div>
        </v-container>
      </v-main>
    </section>
  </v-app>
</template>

<script>
/* eslint-disable*/
import LoginPane from '@/components/auth/LoginPane.vue'
import Notifications from '@/components/shared/Notifications.vue'
import { mapGetters } from 'vuex'
import router from '@/router'
export default {
  name: 'App',
  components: {
    LoginPane,
    Notifications
  },
  beforeMount () {
    this.checklogin()
  },
  data: () => ({
    drawer: false,
    theme: 'light',
    apptitle: 'Document Repository',
    nav: [
      { icon: 'touch_app', text: 'Home', route: '/home' },
      { icon: 'search', text: 'Search', route: '/search' },
      { icon: 'fa-edit', text: 'Utils', route: '/utils' },
      { icon: 'fa-pencil', text: 'Client Access', route: '/clientaccess' },
      { icon: 'lightbulb_outline', text: 'About', route: '/about' }      
    ]
  }),
  methods: {
    checklogin () {
      if (router.history.current.path === '/auth') {
        this.$store.dispatch('auth/setAuthFromUrl', router.history.current.query)
      } else {
        this.$store.dispatch('auth/checkLogin')
      }
    },
    login () {
      this.$store.dispatch('auth/login')
    },
    logout: function () {
      this.$store.dispatch('auth/logout')
    }
  },
  computed: {
    ...mapGetters({
      loggedin: 'auth/loggedin',
      username: 'auth/userFullName',
      ready: 'auth/ready',
      urlLogin: 'auth/urlLogin'
    }),
    isOnline () {
      return this.$store.state.appOnline
    },
    showDrawer () {
      if (this.loggedin) {
        if (this.urlLogin === false) {
          return true
        }
      }
      return false
    }
  }
}
</script>
