<template>
  <div class="login">
    <v-card>
      <div class="login__header">
        <v-icon class="icon" color="primary">fa-sign-in</v-icon>
        <h1 class="display-1 font-weight-thin">{{ appname }}</h1>
      </div>
      <v-card-text>
        <div>
          <v-btn rounded block color="primary" dark @click="login">Login</v-btn>
        </div>
      </v-card-text>
      <v-card-text>
        <p class="text-xs-center caption"> {{ environment }}</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  props: [],
  computed: {
    environment () {
      if (this.env !== 'production') {
        return `Environment: ${this.env}`
      }
      return ''
    }
  },
  data () {
    return {
      env: window.__config.NODE_ENV || 'Local',
      appname: window.__config.APP_NAME
    }
  },
  methods: {
    login: function () {
      this.$store.dispatch('auth/login', this.creds)
    }
  },
  components: {
  }
}
</script>
