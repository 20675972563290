// use @/api in real world
import API from '@/api'
// import Axios from 'axios'

const endpoints = {
  getList: '/FormMetadata/ListForms',
  getSchema: '/CAForm',
  getBuilderSchema: '/Builder/GetSchema',
  getSchemaGeneratedSQL: '/Builder/getSchemaGeneratedSQL',
  save: '/CAForm/:path/submission',
  complete: '/CAForm/:path/completion',
  getSubmission: '/Submission/',
  getPrefillSubmission: '/CAForm/prefillForm',
  getCompletion: '/Completion/',
  getFormActions: '/Actions',
  getActionConstants: '/Actions/GetFormActionTypes',
  getCustomComponents: '/Builder',
  getServiceData: '/Service',
  saveSchema: '/Builder/Create',
  saveSchemaType: '/Builder/createFormType',
  saveIncompleteSubmission: 'Submission/SaveIncomplete',
  getIncompleteSubmissions: 'Submission/GetIncompleteSubmissionsForUser',
  deleteIncompleteSubmission: 'Submission/DeleteIncompleteSubmission',
  logSubmissionError: 'Log/LogSubmissionError'
}

const getList = () => {
  return API.get(endpoints.getList, {
    timeout: 10000
  })
}

const getSchema = (id, formLifeCycle, userId) => {
  if (userId === 'undefined') {
    userId = null
  }
  var reqArgs = { params: { formId: id, formLifeCycle: formLifeCycle, userId: userId } }
  return API.get(endpoints.getSchema, reqArgs)
}

const getBuilderSchema = (id) => {
  var reqArgs = { params: { formId: id } }
  return API.get(endpoints.getBuilderSchema, reqArgs)
}

const getSchemaGeneratedSQL = (id) => {
  var reqArgs = { params: { formId: id } }
  return API.get(endpoints.getSchemaGeneratedSQL, reqArgs)
}

const getCustomComponents = () => API.get(endpoints.getCustomComponents)

const getActionConstants = () => API.get(endpoints.getActionConstants)

const getServiceData = () => API.get(endpoints.getServiceData)

const getSubmission = (id, includeDataFiles) => {
  if (includeDataFiles !== 'false') {
    includeDataFiles = true
  }
  var reqArgs = { params: { includeDataFiles: includeDataFiles } }
  return API.get(endpoints.getSubmission + id, reqArgs)
}

const getPrefilledSubmission = (id, userId, query) => {
  if (userId === undefined || userId === 'undefined') {
    userId = null
  }
  var services = []

  if (query.serviceListIds !== undefined) {
    var serviceIds = query.serviceListIds.split(',')
    serviceIds.forEach(id => {
      services.push({ ServiceId: id })
    })
  }
  var data = { formId: id, userId: userId, serviceId: query.serviceId, ndisNumber: query.ndisNumber, services: services, mergeKey: query.mergeKey, careTypeId: query.careTypeId }
  // var reqArgs = { params: data}
  return API.post(endpoints.getPrefillSubmission, data)
}

const getCompletion = (id) => API.get(endpoints.getCompletion + id)

const getFormActions = (formId, userId, ndisNumber, serviceId) => {
  if (userId === 'undefined') {
    userId = null
  }
  if (ndisNumber === 'undefined') {
    ndisNumber = null
  }
  if (serviceId === 'undefined') {
    serviceId = null
  }
  var reqArgs = { params: { formId: formId, userId: userId, ndisNumber: ndisNumber, serviceId: serviceId } }
  return API.get(endpoints.getFormActions, reqArgs)
}

const save = (data) => API.post(endpoints.save.replace(':path', data.formId), data.submission)

const update = (data) => API.post(`${endpoints.save.replace(':path', data.formId)}/${data.submission._id}`, data.submission)
const complete = (data) => API.put(`${endpoints.complete.replace(':path', data.formId)}/${data.submission._id}`, data.submission)

const upsertSchema = (data) => API.post(endpoints.saveSchema, data)
const upsertSchemaType = (data) => API.post(endpoints.saveSchemaType, data)

const saveIncompleteSubmission = (data) => API.post(endpoints.saveIncompleteSubmission, data)
const getIncompleteSubmissions = (data) => API.get(endpoints.getIncompleteSubmissions, data)
const deleteIncompleteSubmission = (data) => API.post(endpoints.deleteIncompleteSubmission, data)

const logSubmissionError = (data, error) => API.post(endpoints.logSubmissionError, {
  submission: data,
  error: error
})

export default {
  endpoints,
  getList,
  getCustomComponents,
  getServiceData,
  getSchema,
  getBuilderSchema,
  getSchemaGeneratedSQL,
  getSubmission,
  getPrefilledSubmission,
  getFormActions,
  getActionConstants,
  getCompletion,
  save,
  complete,
  update,
  upsertSchema,
  upsertSchemaType,
  saveIncompleteSubmission,
  getIncompleteSubmissions,
  deleteIncompleteSubmission,
  logSubmissionError
}
