import 'bootstrap/dist/css/bootstrap.min.css'

import Vue from 'vue'
import vuetify from './plugins/vuetify'
import formioAuthPlugIn from './plugins/formio/formioAuthPlugin'
import 'formiojs/dist/formio.full.min.css'
import './sass/global.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import { Formio } from 'formiojs'
// import { FormioForm } from 'formiojs/full';
import FormioContrib from './contrib/index'
// import TestFileComponent from './contrib/components/FileExtended/FileExt2'

Formio.use(FormioContrib)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    store.dispatch('indexedDb/checkUnsubmits')
    window.addEventListener('offline', () => {
      store.dispatch('setConnected', false)
    })
    window.addEventListener('online', () => {
      store.dispatch('setConnected', true)
      store.dispatch('indexedDb/checkUnsubmits')
    })
    navigator.serviceWorker.onmessage = (event) => {
      /* eslint-disable no-console */
      console.log('message received', event.data)
      /* eslint-enable no-console */
      store.dispatch('handleServiceWorkerMessage', event)
    }
    store.dispatch('setConnected', navigator.onLine)
    formioAuthPlugIn.install()
    // Formio.registerComponent('testFile2', TestFileComponent)
  }
}).$mount('#app')
