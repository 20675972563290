import axios from 'axios'
import auth from '../store/modules/auth'
import authHeaders from './authHeaders'

let API_URL = window.__config.API_URL || 'https://formsapi-int.myhavenweb.com/'

if (process.env.VUE_APP_API) {
  API_URL = process.env.VUE_APP_API
}
const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*.myhavenweb.com'
  }
})

apiClient.interceptors.request.use(function (config) {
  authHeaders.setAuthorizationHeader(config)
  return config
})

// @azagoskin: although we have 'automaticSilentRenew: true' sometimes we may end up with
// invalid (expired) access token, which in turn leads to 401 responses. In this case we refresh the token
// and repeat the request
apiClient.interceptors.response.use(null, function (error) {
  if (error.config && error.response && error.response.status === 401) {
    return auth.actions.signinSilent().then(() => {
      authHeaders.setAuthorizationHeader(error.config)
      return axios.request(error.config)
    })
  }
  return Promise.reject(error)
})

export default apiClient
