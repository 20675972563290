<template>
  <section>
    <v-container fluid>
      <v-row class="mx-4">
        <v-col>
          <v-select label="form picker" v-model="form" return-object clearable :items="forms" item-text="name">
          </v-select>
        </v-col>
         <v-col>
          <v-btn class = "primary" :loading="initialising"
            :disabled = "buttonDisabled"
            @click="generateLink()">
            Generate link
          </v-btn>
        </v-col>
      </v-row>
       <v-row class="mx-4">
        <v-text-field
          label="Generated link..."
          v-model="generatedLink"
          hide-details></v-text-field>
      </v-row>
     </v-container>
  </section>
</template>

<script>
import searchApi from '@/api/searchApi'
export default {
  name: 'GenerateLink',
  data: () => ({
    forms: [],
    form: null,
    generatedLink: '',
    initialising: true
  }),
  async beforeMount () {
    this.initialise()
  },
  mounted () {
  },
  methods: {
    async generateLink () {
      var host = `${window.location.protocol}//${window.location.host}`
      var token = await searchApi.getFormLinkAuth(host, this.form._id)
      this.generatedLink = token.data
      navigator.clipboard.writeText(this.generatedLink)
      this.$store.dispatch('notifications/showSuccess', { text: 'text copied to clipboard' }, { root: true })
    },
    async initialise () {
      try {
        this.initialising = true
        const [ret] = await Promise.all([searchApi.getSchemaHistory()])
        this.forms = ret.data
      } catch (error) {
        this.fetchError = error.message
      } finally {
        this.initialising = false
      }
    }
  },
  beforeDestroy () {
  },
  computed: {
    buttonDisabled () {
      return this.form === null
    }
  },
  components: {
  }
}
</script>

<style>

</style>
