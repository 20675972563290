<template>
  <section>
      <v-container v-if="this.getTotalDocuments()>0" grid-list-xs>
        <v-layout>
           <v-expansion-panels v-model="panel">
            <v-expansion-panel >
              <v-overlay absolute="absolute" :value="actions.loading">
                <v-progress-circular  v-if="actions.loading" indeterminate color="primary"></v-progress-circular>
              </v-overlay>
              <v-expansion-panel-header :color="panelHeaderColour">
                {{actionsTitle}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
              <v-container v-if="!actions.loading" py-0>
               <v-row>
                <div v-for="(formAction, fIndex) in actions.data" :key="fIndex">
                  <div v-if="formAction.documents.length>0">
                    <v-col>
                      <v-list elevation="1">
                         <v-list-group
                          v-model="groupArray[fIndex]"
                          prepend-icon="mdi-file-document">
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title v-text="getFormActionTitle(formAction)"></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          <v-list-item dense
                            v-for="(filesrc, index) in formAction.documents" :key="index"
                            @click="downloadWithAxios(filesrc.url, `${filesrc.name}`, filesrc.loading)">
                            <v-overlay absolute="absolute" :value="filesrc.loading">
                              <v-progress-circular  v-if="filesrc.loading" indeterminate color="primary"></v-progress-circular>
                            </v-overlay>
                            <v-list-item-content
                              :class="{ 'orange--text' : (!filesrc.fromSubmission) && submissionMode }"
                              v-text="`[${filesrc.documentResponse.documentType}] '${filesrc.name}'`">
                            </v-list-item-content>
                            <v-tooltip bottom>
                              <template  v-slot:activator="{ on, attrs }">
                                <v-list-item-icon   v-if="(!filesrc.fromSubmission) && submissionMode"
                                  v-bind="attrs"
                                  v-on="on">
                                    <v-icon class="orange--text">mdi-wrench</v-icon>
                                </v-list-item-icon>
                              </template>
                              <span >This file was not present on original form submission</span>
                            </v-tooltip>
                            <v-list-item-icon >
                              <v-icon class="primary--text">mdi-download</v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-group>
                      </v-list>
                    </v-col>
                  </div>
                 </div>
                </v-row>
              </v-container>
             </v-expansion-panel-content>
            </v-expansion-panel>
           </v-expansion-panels>
        </v-layout>
      </v-container>
  </section>
</template>
<script>

import API from '@/api'
import idbs from '@/api/indexedDBService'
import * as moment from 'moment'
export default {
  name: 'FormActionWidget',
  props: ['actions', 'submissionMode'],
  components: {
  },
  data: () => ({
    downloadInProgess: false,
    panel: null,
    panelHeaderColour: 'primary',
    groupArray: new Array(50).fill(true) // just ensure more in array than nested form actions
  }),
  methods: {
    forceFileDownload (url, data, title) {
      const objectUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = objectUrl
      link.setAttribute('download', title)
      link.click()
    },
    setPanelHeader () {
      const docs = this.getTotalDocuments()
      if (docs > 0) {
        this.panel = 0
      } else {
        this.panel = null
      }
    },
    storeFileLocal (url, data) {
      // store data in indexedDb
      var expiry = moment(new Date()).add(1, 'd').toDate()
      idbs.saveFileToStorage(url, data, expiry)
    },
    async getFileLocal (url) {
      return idbs.getFileFromStorage(url)
    },
    setLoadingOverlay (url, showOverlay) {
      this.actions.data.forEach(element => {
        if (element.documents) {
          element.documents.forEach(doc => {
            if (doc.url === url) {
              doc.loading = showOverlay
            }
          })
        }
      })
    },
    async downloadWithAxios (url, title, isLoading) {
      // look for url in indexedDb first
      if (!isLoading) {
        this.setLoadingOverlay(url, true)
        var localFile = await this.getFileLocal(url)
        if (!localFile) {
          this.downloadInProgess = true
          var options = {
            responseType: 'blob'
          }
          API.get(url, options)
            .then(response => {
              this.storeFileLocal(url, response.data)
              this.forceFileDownload(url, response.data, title)
              this.downloadInProgess = false
              this.setLoadingOverlay(url, false)
            })
            .catch(() => {
              this.downloadInProgess = false
              this.setLoadingOverlay(url, false)
            })
        } else {
          this.forceFileDownload(url, localFile.data, title)
          this.setLoadingOverlay(url, false)
        }
      }
    },
    getTotalDocuments () {
      let docCount = 0
      this.actions.data.forEach(element => {
        if (element.documents.length > 0) {
          docCount += element.documents.length
        }
      })
      return docCount
    },
    getFormActionsTitle () {
      const docCount = this.getTotalDocuments()
      if (docCount > 0) {
        return `${docCount} documents attached`
      }
      return 'No actions for this form'
    },
    getFormActionTitle (formAction) {
      var formName = 'form'
      if (formAction.formName) {
        formName = formAction.formName
      }
      return `${formName}`
    }
  },
  computed: {
    actionsTitle () {
      if (this.actions && !this.actions.loading) {
        return this.getFormActionsTitle(this.actions)
      }
      return 'Fetching form actions...'
    }
  },
  watch: {
    actions: {
      deep: true,
      handler (newValue, oldValue) {
        if (!newValue.loading) {
          this.formActionsTitle = this.getFormActionsTitle(newValue)
          this.setPanelHeader()
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/scss" lang="scss">
</style>
