<template>
  <v-dialog persistent v-model="visible" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>Network Activity Restored</v-card-title>
      <v-card-text class="pt-3">
        Further editing of this form is no longer allowed, as it has now been submitted.
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text router-link :to="{path: '/home'}">Return to forms list</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormOnlineDialog',
  props: ['visible']
}
</script>

<style>
</style>
