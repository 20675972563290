// use @/api in real world
import API from '@/api'
// import Axios from 'axios'

const endpoints = {
  subscribe: '/PushNotification/AddSubscription',
  pushNotification: '/PushNotification/push',
  failedToSubscribe: '/PushNotification/failedToSubscribe'
}

const subscribe = (data) => API.post(endpoints.subscribe, data)
const pushNotification = (data) => API.post(endpoints.pushNotification, data)
const failedToSubscribe = (data) => API.post(endpoints.failedToSubscribe, { syncId: data })
export default {
  subscribe,
  pushNotification,
  failedToSubscribe
}
