import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import notifications from './modules/notifications'
import refreshContent from './modules/refreshContent'
import formHandler from './modules/forms/formHandler'
import formList from './modules/forms/formList'
import incompleteSubmissions from './modules/forms/incompleteSubmissions'
import formActions from './modules/forms/formActions'
import schemaBuilder from './modules/schema/schemaBuilder'
import indexedDb from './modules/indexedDb'
// import registerServiceWorker from '@/registerServiceWorker'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    appOnline: navigator.onLine
  },
  actions: {
    setConnected ({ commit }, payload) {
      commit('SET_CONNECTION', payload)
    },
    handleServiceWorkerMessage ({ dispatch }, event) {
      // if (event.data && event.data.type === 'SYNC_ID_UPDATED') {
      //   localStorage.removeItem('sync_id')
      //   registerServiceWorker.subscribe(event.data.syncId, true)
      // }
      if (event.data && event.data.type === 'SYNC_DATA_UPDATED') {
        dispatch('incompleteSubmissions/getAvailableSubmissions')
      }
    }
  },
  getters: {
    getField
  },
  mutations: {
    SET_CONNECTION (state, payload) {
      state.appOnline = payload
    },
    updateField
  },
  modules: {
    auth: auth,
    notifications: notifications,
    formHandler: formHandler,
    formList: formList,
    incompleteSubmissions: incompleteSubmissions,
    schemaBuilder: schemaBuilder,
    indexedDb: indexedDb,
    formActions: formActions,
    refreshContent: refreshContent
  }
})
