<template>
  <section>
    <h5 class="pa-3">
      <v-icon>description</v-icon> Saved Forms
    </h5>
    <v-divider></v-divider>
    <v-data-table
    :headers="headers"
    :items="submissionItems"
    :items-per-page="5"
    :loading="loading"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    class="elevation-1"
  >
    <template v-slot:[`item.date`]="{ item }">
      <span>{{`${dateSettings.dateTimeFormat(item.date)}`}}</span>
    </template>
    <template v-slot:[`item.expiryDate`]="{ item }">
      <span>{{`${dateSettings.dateTimeFormat(item.expiryDate)}`}}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn class="ml-10" small color="primary" @click="confirmDeleteItem(item.id)" dark>
        <v-icon>mdi-delete-forever-outline</v-icon>
      </v-btn>
      <v-btn class="ml-10 float-xl-right" small color="primary" router-link :to="{path: `/forms/resume/${decodeURI(item.id)}`}" dark>
        Open
        <!-- <v-icon>mdi-creation</v-icon> -->
      </v-btn>
    </template>
  </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm delete</v-card-title>
        <v-card-text>Are you sure you want to delete?.</v-card-text>
        <v-card-actions>
          <v-overlay absolute="absolute" :value="deleting">
            <v-progress-circular  v-if="deleting" indeterminate color="primary"></v-progress-circular>
          </v-overlay>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import { dateSettings } from '@/constants.js'
export default {
  name: 'SavedFormList',
  data: () => ({
    headers: [
      {
        text: 'Name',
        value: 'schema.schema.name',
        align: 'start',
        sortable: false
      },
      { text: 'Date', value: 'date', type: 'date' },
      { text: 'Expiry', value: 'expiryDate', type: 'date' },
      { text: '', value: 'actions', sortable: false }
    ],
    sortBy: 'date',
    sortDesc: true,
    dialog: false,
    currentDeleteId: null,
    deleting: false
  }),
  async mounted () {
    await this.$store.dispatch('incompleteSubmissions/getAvailableSubmissions')
  },
  methods: {
    async deleteItem () {
      this.deleting = true
      try {
        await this.$store.dispatch('incompleteSubmissions/deleteIncompleteSubmission', this.currentDeleteId)
      } catch (e) {
        this.$store.dispatch('notifications/showError', { text: `Failed to delete. ${e}.` }, { root: true })
      } finally {
        this.currentDeleteId = null
        this.dialog = false
        this.deleting = false
      }
    },
    confirmDeleteItem (id) {
      this.dialog = true
      this.currentDeleteId = id
    },
    moment (date) {
      return moment(date)
    }
  },
  computed: {
    ...mapGetters({
      submissionItems: 'incompleteSubmissions/submissionItems'
    }),
    loading () {
      return this.$store.state.formList.loading
    },
    dateSettings () {
      return dateSettings
    }
  }
}
</script>

<style>

</style>
