<template>
  <section>
  <div>
      <v-row class="px-3">
        <v-col>
        <h5>Actions</h5>
        <v-row>
          <v-col>
            <v-row class="px-3" v-for="(item, i) in settings" :key="i">
              <v-col>
                  <v-select :items="actionTriggers"
                  v-model="settings[i].key"
                  label="Triggers">
                </v-select>
              </v-col>
              <v-col>
                  <v-select :items="getActions(settings[i].key).actions"
                  v-model="settings[i].value"
                  label="Actions">
                </v-select>
              </v-col>
              <v-btn class="my-5 mx-1" fab dark small color="primary" v-on:click="removeSetting(i)">
                <v-icon dark>mdi-minus</v-icon>
              </v-btn>
            </v-row>
            </v-col>
          </v-row>
          <div class="text-left">
            <v-btn text color="primary" v-on:click="addSetting">Add action</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>

// import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  name: 'SchemaActions',
  data: () => ({
  }),
  props: ['settings'],
  beforeMount () {
    this.$store.dispatch('formActions/getActionConstants')
  },
  methods: {
    addSetting (e) {
      var obj = {
        key: null,
        value: null
      }
      this.settings.push(obj)
    },
    removeSetting (index) {
      this.settings.splice(index, 1)
    },
    getActions (key) {
      var ret = this.actionTriggersFull.find(function (o) { return o.name === key })
      if (ret === undefined) {
        ret = this.actionTriggersFull[0]
      }
      return ret
    }
  },
  computed: {
    ...mapGetters({
      actionTriggers: 'formActions/actionTriggers',
      actionTriggersFull: 'formActions/actionTriggersFull'
    }),
    showLoading () {
      return this.$store.state.schemaBuilder.loading || this.$store.state.schemaBuilder.loadingSchema
    }
  },
  components: {
  }
}
</script>
