<template>
  <section>
    <form-action-widget :actions="formActions" :submissionMode="true"></form-action-widget>
    <form-widget :formSchemaData="formSchema" :formOptions="formOptions" :storeIncompleteSubmission="false" :showClearButton="false"></form-widget>
  </section>
</template>

<script>
import FormWidget from '@/components/forms/FormWidget'
import FormActionWidget from '@/components/forms/FormActionWidget'
import { mapGetters } from 'vuex'
export default {
  name: 'FormsSubmission',
  mounted () {
    const params = this.$route.params
    const query = this.$route.query

    this.$store.dispatch('formHandler/getStoredSchemas')
    this.$store.dispatch('formHandler/resetForm')
    this.$store.dispatch('formHandler/setOptions', { readOnly: true })
    this.$store.dispatch('formHandler/getFormSubmission', { params: params, query: query })
    this.$store.dispatch('formHandler/setExisting', true)
    this.$store.dispatch('formActions/getSubmissionActions', params.id)
    this.$store.dispatch('formHandler/setExternalIdentifierKey', query.externalIdentifierKey)
  },
  beforeDestroy () {
    // this.$store.dispatch('formHandler/resetForm')
  },
  computed: {
    ...mapGetters({
      formSchema: 'formHandler/formSchema',
      formActions: 'formActions/formActions',
      formOptions: 'formHandler/formOptions'
    })
  },
  components: {
    FormWidget,
    FormActionWidget
  }
}
</script>

<style>

</style>
