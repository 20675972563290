import formsApi from '@/api/formsApi'
import idbs from '@/api/indexedDBService'
import router from '@/router'
import { dataHelpers } from '@/store/helpers'
import { find, reject, cloneDeep, map } from 'lodash'
import * as moment from 'moment'
const getDefaultState = () => {
  return {
    title: 'formHandler',
    storedSchemas: [],
    isExisting: false,
    isCompletion: false,
    form: {
      loading: false,
      submitted: false,
      schemaId: null,
      schema: {}
    },
    submissionCount: 0,
    submissionLoading: false,
    submission: {
      clientUpdated: null,
      created: null,
      form: null,
      data: {},
      complete: false,
      documents: [],
      externalIdentifierKey: null,
      expiryDate: null
    },
    prefillData: {
      data: {},
      errorList: [],
      isValid: false,
      serverCheckComplete: false
    },
    options: {
      noAlerts: true, // prevent formio submission messages
      buttonSettings: {
        showCancel: false, // hide formio wizard cancel button
        showSubmit: false // hide formio wizard submit
      },
      readOnly: false
    },
    errors: false
  }
}

const validatePrefillData = (prefillData, submissionData, name, errorList) => {
  var canIterate = typeof (prefillData) === 'object'
  if (!canIterate) {
    if (prefillData !== submissionData) {
      // console.log('not equal', name, prefillData, submissionData)
      errorList.push(name)
    } else {
      // console.log('cannot iterate', name, prefillData, submissionData)
      errorList.push(name)
    }
    return
  }
  for (const x in prefillData) {
    if (submissionData[x] === undefined) {
      errorList.push(name)
      return
    }
    if (prefillData[x] !== submissionData[x]) {
      if (!prefillData[x] && !submissionData[x]) {
        // console.log('no match3', x, prefillData[x], submissionData[x])
        errorList.push(name)
        return
      }
      validatePrefillData(prefillData[x], submissionData[x], x, errorList)
    }
  }
}
const state = getDefaultState()

const formSchemaGetter = (schemaId) => {
  if (!schemaId) return false
  const currentSchema = find(state.storedSchemas, { id: schemaId })
  const returnedSchema = currentSchema !== undefined ? currentSchema.schema : false
  return returnedSchema
}
const getters = {
  formSchema: state => {
    if (!state.form.schemaId) return false
    return formSchemaGetter(state.form.schemaId)
  },
  userDetails: state => {
    return `${state.submission.userName} {${state.submission.userEmail}} {${state.submission.employeeId}}`
  },
  submission: state => {
    return state.submission
  },
  prefillData: state => {
    return state.prefillData
  },
  formErrors: state => {
    return state.errors
  },
  formLoading: state => {
    return state.form.loading || state.submissionLoading
  },
  formOptions: state => {
    return state.options
  },
  storedSchemas: state => {
    return state.storedSchemas
  }
}

const actions = {
  setExisting ({ commit }, status) {
    commit('SET_IS_EXIST', status)
  },
  resetForm ({ commit }) {
    commit('RESET')
  },
  setCompletion ({ commit }, completion) {
    commit('SET_COMPLETION', completion)
  },
  async storeForm ({ commit, dispatch, rootState }, params) {
    if (state.form.schemaId === null || state.form.schemaId === false) {
      return
    }
    state.submission._id = dataHelpers.nullishOperator(state.submission._id, dataHelpers.createId('local_'))
    var compositeKey = dataHelpers.getIncompleteSubmissionKey(state.form.schemaId, params.query)

    var timeFromNow = moment(new Date()).add(1, 'd').toDate()
    if (!state.submission.expiryDate || params.expiryDate) {
      var expiryDate = params.expiryDate
      if (!expiryDate) {
        // if expiry never set, give 1 day temp storage
        expiryDate = timeFromNow
      }
      commit('SET_SUBMISSION_EXPIRY', expiryDate)
    }
    // always ensure any "unsaved" forms are given a temp save time of 1 min
    if (state.submission.expiryDate < timeFromNow) {
      commit('SET_SUBMISSION_EXPIRY', timeFromNow)
    }

    dispatch('indexedDb/storeFormLocal', {
      formId: state.form.schemaId,
      submission: state.submission,
      formActions: rootState.formActions.data,
      id: compositeKey,
      expiryDate: state.submission.expiryDate,
      storeRemote: params.storeRemote,
      prefillData: state.prefillData
    }, { root: true })
    if (params.storeRemote) {
      await dispatch('incompleteSubmissions/sendToServer', {
        formId: state.form.schemaId,
        submission: state.submission,
        formActions: rootState.formActions.data,
        id: compositeKey,
        expiryDate: state.submission.expiryDate
      }, { root: true })
    }
    if (params.showSuccess) {
      dispatch('notifications/showSuccess', { text: 'Save successful', timeout: 1000, closeBtn: false, align: 'center' }, { root: true })
    }
  },
  saveForm ({ commit, dispatch, rootState, state }, form) {
    if (state.submission.complete) {
      alert('This form has already been completed. You cannot submit completed forms!')
      return
    }
    state.submission.complete = state.isCompletion
    commit('SET_SUBMISSION_ACTIONDATA', rootState.formActions.data)
    commit('SET_SUBMISSION_FORMDATA', form)
    const endpoint = state.isExisting ? formsApi.endpoints.update : formsApi.endpoints.save
    let apimethod = 'POST'
    state.submission._id = dataHelpers.nullishOperator(state.submission._id, dataHelpers.createId('local_'))
    const submissionId = state.submission._id
    // if im offline, i want to check local DB incase record already exists, this way we can edit it
    if (!rootState.appOnline) {
      const localRecord = find(rootState.indexedDb.unsubmittedForms, { id: submissionId })
      apimethod = localRecord !== undefined ? localRecord.method : apimethod
    }
    dispatch('indexedDb/saveFormLocal', {
      method: apimethod,
      endpoint: endpoint,
      formId: state.form.schemaId,
      payload: state.submission,
      id: submissionId,
      isExisting: state.isExisting
    }, { root: true })
    if (!rootState.appOnline) {
      dispatch('notifications/showError', { text: 'APP OFFLINE: Data saving locally until back online' }, { root: true })
      if ('serviceWorker' in navigator && 'SyncManager' in window) {
        navigator.serviceWorker.ready.then(registration => {
          registration.sync.register('sync-schemas')
        })
      }
    }
  },
  submitFormRemote ({ commit, dispatch, state, rootState }, { submission, redirect }) {
    let apiEndpoint = null
    // apply a date to form content
    if (Object.keys(submission.submission).length) {
      submission.submission.date = submission.date
    }
    switch (submission.endpoint) {
      case formsApi.endpoints.save:
        apiEndpoint = () => formsApi.save(submission)
        break
      case formsApi.endpoints.update:
      case formsApi.endpoints.complete:
        apiEndpoint = () => formsApi.update(submission)
        break
    }
    apiEndpoint().then(res => {
      commit('SET_SUBMISSION_ID', res.data)
      dispatch('indexedDb/clearItem', submission, { root: true })
      commit('SET_SUBMISSION_COUNT')
      if (redirect) {
        // router.replace(`/forms/submission/${state.submission._id}?includeDataFiles=false`)
        router.replace(`/forms/submissionsuccess/${state.submission._id}`)
        dispatch('notifications/showSuccess', { text: `Submission # ${state.submission._id} sent to server` }, { root: true })
      }
    }).catch(error => {
      const submitSizeLimit = 30000000
      if (error) {
        if (error.response) {
          if (error.response.config.data.length < submitSizeLimit) {
            formsApi.logSubmissionError(submission, { error: error, response: error.response }).then(res => {
              dispatch('indexedDb/clearItem', submission, { root: true })
            }).catch(logError => {

            })
            dispatch('notifications/showError', { text: `Submission failed for form: ${submission.formId} ${error.response.data.message}"` }, { root: true })
          } else {
            // clear submission which is too large
            dispatch('indexedDb/clearItem', submission, { root: true })
          }
        } else {
          if (error.message === 'Network Error') {
            dispatch('notifications/showError', { text: 'Submission failed due to a network error. Please try again when you have internet connection.' }, { root: true })
          } else {
            dispatch('notifications/showError', { text: `Submission failed for form: ${submission.formId} ${error.message}` }, { root: true })
            // attempt to send failing submission to server log
            formsApi.logSubmissionError(submission, error).then(res => {
              dispatch('indexedDb/clearItem', submission, { root: true })
            }).catch(logError => {

            })
          }
        }
      }
    })
  },
  processUnsubmittedForms ({ rootState, dispatch }) {
    const unsubs = rootState.indexedDb.unsubmittedForms
    unsubs.forEach(newSubmission => {
      dispatch('submitFormRemote', { submission: newSubmission, redirect: false })
    })
  },
  async getFormSchema ({ commit, dispatch, rootState, getters }, params) {
    if (rootState.appOnline) {
      commit('SET_SCHEMA_ID', params.id)
      var formExists = getters.formSchema
      if (!formExists) {
        commit('SET_FORM_LOADING', true)
      }
      try {
        var res = await formsApi.getSchema(params.id, params.formLifeCycle, params.userId)
        commit('UPDATE_SCHEMA_LIST', res)
        commit('SET_SCHEMA_ID', params.id)
        dispatch('indexedDb/saveSchemaLocal', res.data, { root: true })
      } catch (error) {
        if (error) {
          commit('SET_ERROR')
          dispatch('getFormSchemaLocal', params.id)
        }
      } finally {
        commit('SET_FORM_LOADING', false)
      }
    } else {
      commit('SET_ERROR')
      dispatch('getFormSchemaLocal', params.id)
    }
  },
  getFormSchemaLocal ({ commit }, id) {
    commit('SET_FORM_LOADING', true)
    commit('SET_SCHEMA_ID', id)
    commit('SET_FORM_LOADING', false)
  },
  getFormSubmission ({ commit, dispatch, rootState }, p) {
    var params = p.params
    var query = p.query
    var id = params.id
    const submissionActive = state.submission._id === id
    if (rootState.appOnline && !submissionActive) {
      commit('SET_SUBMISSION_LOADING', true)
      formsApi.getSubmission(id, query.includeDataFiles).then((res) => {
        commit('SET_SUBMISSION', res.data)
        var reqArgs = { id: res.data.form, formLifeCycle: 1 }
        dispatch('getFormSchema', reqArgs)
      }).catch(error => {
        if (error) {
          dispatch('notifications/showError', { text: `Error trying to retrieve submission [${error}]` }, { root: true })
        }
      }).finally(() => {
        commit('SET_SUBMISSION_LOADING', false)
      })
    } else if (!rootState.appOnline && !submissionActive) {
      commit('SET_FORM_LOADING', true)
      idbs.getStoredSubmit(id).then((res) => {
        commit('SET_SUBMISSION', res.submission.data)
        commit('SET_SCHEMA_ID', res.formId)
      }).finally(() => {
        commit('SET_SUBMISSION_LOADING', false)
      })
    }
  },
  async getPrefilledSubmission ({ commit, dispatch }, params) {
    commit('SET_SUBMISSION_LOADING', true)
    var query = params.query
    try {
      var res = await formsApi.getPrefilledSubmission(params.id, params.userId, query)
      commit('SET_SUBMISSION_DATA', res.data.submission.data)
      commit('SET_PREFILL_SUBMISSION_DATA', res.data.submission.data)
      if (res.data.errorMessages.length > 0) {
        dispatch('notifications/showError', { text: `Some errors occurred while populating form data. [${res.data.errorMessages}]` }, { root: true })
      }
    } finally {
      // prefill data must be valid because it is identical to submission data at this point!
      state.prefillData.isValid = true
      state.prefillData.serverCheckComplete = true
      commit('SET_SUBMISSION_LOADING', false)
    }
  },
  async getPrefilledValidationData ({ dispatch }, params) {
    var query = params.query
    try {
      var schemaDetail = formSchemaGetter(state.form.schemaId)
      if (!schemaDetail.properties.staleDataCheck) {
        state.prefillData.isValid = true
        return
      }
      state.prefillData.serverCheckComplete = false
      state.prefillData.isValid = false
      var res = await formsApi.getPrefilledSubmission(params.id, params.userId, query)
      var errorList = []
      validatePrefillData(res.data.submission.data, state.prefillData.data, '', errorList)
      state.prefillData.errorList = errorList
      state.prefillData.isValid = errorList.length === 0
      if (res.data.errorMessages.length > 0) {
        dispatch('notifications/showError', { text: `Some errors occurred while attempting to fetch prefill form data. [${res.data.errorMessages}]` }, { root: true })
      }
      if (errorList.length > 0) {
        dispatch('notifications/showError', { text: 'Form data is out of date. Please clear the form' }, { root: true })
      }
    } finally {
      state.prefillData.serverCheckComplete = true
    }
  },
  async refreshFormData ({ rootState, dispatch, commit }, params) {
    if (rootState.appOnline) {
      // remove from incomplete submission list
      // var prefillData = rootState.formHandler.prefillData
      // if (!(!prefillData.isValid && prefillData.serverCheckComplete && prefillData.errorList.length === 0)) {
      //   var compositeKey = dataHelpers.getIncompleteSubmissionKey(params.id, params.query)
      //   idbs.removeIncompleteFromStorage(compositeKey)
      // }
      var compositeKey = dataHelpers.getIncompleteSubmissionKey(params.id, params.query)
      await idbs.removeIncompleteFromStorage(compositeKey)
      var addPath = router.history.current.fullPath.replace('/resume/', '/add/')

      if (addPath !== router.history.current.fullPath) {
        router.replace(addPath)
      } else {
        router.go()
      }
      // router.push({ path: addPath })
    } else {
      dispatch('notifications/showError', { text: 'Cannot refresh as you are currently offline' }, { root: true })
    }
  },
  async getIncompleteSubmission ({ commit, dispatch }, params) {
    commit('SET_SUBMISSION_LOADING', true)
    var res = await idbs.getStoredIncomplete(params.id)
    if (!res) {
      await dispatch('incompleteSubmissions/fetchAllFromServer', {}, { root: true })
      res = await idbs.getStoredIncomplete(params.id)
    }
    if (res && res !== 'undefined') {
      commit('SET_SUBMISSION_DATA', res.submission.data)
      if (res.prefillData) {
        commit('SET_PREFILL_SUBMISSION_DATA', res.prefillData.data)
      }
      commit('SET_SUBMISSION_EXPIRY', res.expiryDate)
      commit('SET_SUBMISSION_DATE', res.date)
      commit('SET_SUBMISSION_ID', res.submission._id)
    }
    commit('SET_SUBMISSION_LOADING', false)
    return res
  },
  async getStoredSchemas ({ commit }) {
    var schemas = await idbs.getAllStoredSchemas()
    commit('SET_ALL_STORED_SCHEMAS', schemas)
  },
  setExternalIdentifierKey ({ commit }, externalIdentifierKey) {
    commit('SET_EXTERNAL_IDENTIFIER_KEY', externalIdentifierKey)
  },
  setOptions ({ commit }, options) {
    commit('SET_OPTIONS', options)
  }
}
const mutations = {
  RESET (state) {
    state.form.schema = {}
    state.form.schemaId = false
    state.submission = {
      clientUpdated: null,
      created: null,
      data: {},
      complete: false,
      documents: []
    }
    state.isExisting = false
    state.isCompletion = false
    state.submissionLoading = false
    state.options = {
      // prevent formio submission messages
      noAlerts: true,
      buttonSettings: {
        showCancel: false, // hide formio wizard cancel button
        // showNext: false,
        // showPrevious: false,
        showSubmit: false // hide formio wizard submit
      },
      readOnly: false
    }
    state.errors = false
    state.prefillData = {
      data: {},
      errorList: [],
      isValid: false,
      serverCheckComplete: false
    }
  },
  SET_IS_EXIST (state, data) {
    state.isExisting = data
  },
  SET_COMPLETION (state, data) {
    state.isCompletion = data
  },
  SET_SUBMISSION (state, data) {
    var externalIdentifierKey = state.submission.externalIdentifierKey
    state.submission = data
    state.submission.externalIdentifierKey = externalIdentifierKey
  },
  SET_SUBMISSION_DATA (state, data) {
    state.submission.data = data
  },
  SET_PREFILL_SUBMISSION_DATA (state, data) {
    var copy = JSON.parse(JSON.stringify(data))
    state.prefillData.data = copy
  },
  SET_SUBMISSION_EXPIRY (state, data) {
    state.submission.expiryDate = data
  },
  SET_SUBMISSION_DATE (state, data) {
    state.submission.date = data
  },
  SET_SUBMISSION_COUNT (state) {
    state.submissionCount = state.submissionCount + 1
  },
  SET_SUBMISSION_LOADING (state, data) {
    state.submissionLoading = data
  },
  SET_SUBMISSION_ID (state, data) {
    state.submission._id = data
    if (state.isCompletion) {
      state.submission.complete = true
    }
  },
  SET_SUBMISSION_FORMDATA (state, formData) {
    state.submission.data = formData.data
    state.submission.metadata = formData.metadata
  },
  SET_SUBMISSION_ACTIONDATA (state, actionData) {
    function getFormActionData (action) {
      return {
        formId: action.formId,
        documents: map(action.documents, 'documentId')
      }
    }
    state.submission.documents = map(actionData, getFormActionData)
  },
  SET_SCHEMA_ID (state, id) {
    state.form.schemaId = id
  },
  SET_ALL_STORED_SCHEMAS (state, schemas) {
    state.storedSchemas = schemas
  },
  UPDATE_SCHEMA_LIST (state, schema) {
    if (schema.data === null) return false
    const newState = cloneDeep(state.storedSchemas)
    const cleanState = reject(newState, { id: schema.data._id })
    const schemaObject = {
      date: new Date(),
      id: schema.data._id,
      schema: schema.data
    }
    cleanState.push(schemaObject)
    state.storedSchemas = cleanState
  },
  SET_FORM_LOADING (state, data) {
    state.form.loading = data
  },
  SET_ERROR (state) {
    state.errors = true
  },
  SET_EXTERNAL_IDENTIFIER_KEY (state, data) {
    state.submission.externalIdentifierKey = data
  },
  SET_OPTIONS (state, data) {
    state.options = data
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
