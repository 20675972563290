export default [
  {
    type: 'checkbox',
    label: 'Confirm delete rows',
    key: 'confirmDelete',
    defaultValue: true,
    input: true,
    weight: 1,
    tooltip: 'Check this to confirm deletion of rows.'
  },
  {
    type: 'checkbox',
    label: 'Hide empty grid',
    key: 'hideEmptyGrid',
    defaultValue: false,
    input: true,
    weight: 2,
    tooltip: 'Check this to hide grid with no rows. Useful for hiding grids that use pre-filled data.'
  }
]
