<template>
  <section>
  <div>
    <h5>Properties</h5>
    <v-row class="px-3">
      <v-col>
        <v-select
          :items="displayType"
          v-model="display"
        label="Form type"
      ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          label="documentTypeId"
          v-model="properties.documentTypeId"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
      </v-row>
      <v-row class="px-3">
        <v-col>
          <v-checkbox
            v-model="properties.saveButton"
          label="Show save button"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="properties.staleDataCheck"
            label="Stale data check"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  name: 'SchemaProperties',
  data: () => ({
    displayType: ['form', 'wizard']
  }),
  beforeMount () {
  },
  methods: {
  },
  computed: {
    ...mapFields([
      'schemaBuilder.schema.properties',
      'schemaBuilder.schema.display'
    ]),
    ...mapGetters({
    })
  },
  components: {
  }
}
</script>
