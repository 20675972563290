<template>
  <section>
    <form-action-widget :actions="formActions" :submissionMode="false"></form-action-widget>
    <form-widget :formErrors="formErrors" :formSchemaData="formSchema" :formOptions="formOptions" :storeIncompleteSubmission="true"></form-widget>
  </section>
</template>
<script>
import FormWidget from '@/components/forms/FormWidget'
import FormActionWidget from '@/components/forms/FormActionWidget'
import { mapGetters } from 'vuex'
import idbs from '@/api/indexedDBService'
import { dataHelpers } from '@/store/helpers'
import router from '@/router'
export default {
  name: 'FormsAdd',
  async beforeMount () {
  },
  async mounted () {
    const params = this.$route.params
    const query = this.$route.query

    var compositeKey = dataHelpers.getIncompleteSubmissionKey(params.id, query)
    var res = await idbs.getStoredIncomplete(compositeKey)
    if (res) {
      router.replace(`/forms/resume/${compositeKey}&externalIdentifierKey=${query.externalIdentifierKey}`)
      return
    }
    var userId = dataHelpers.nullishOperator(query.userId, window.localStorage.user_id)
    this.$store.dispatch('formHandler/resetForm')
    await this.$store.dispatch('formHandler/getPrefilledSubmission', { id: params.id, query: this.$route.query, userId: userId })
    await this.$store.dispatch('formHandler/getStoredSchemas')
    this.$store.dispatch('formHandler/getFormSchema', { id: params.id, formLifeCycle: 0, userId: userId, serviceId: query.serviceId, ndisNumber: query.ndisNumber })

    this.$store.dispatch('formHandler/setExisting', false)
    this.$store.dispatch('formActions/getFormActions', { formId: params.id, userId: userId, ndisNumber: query.ndisNumber, serviceId: query.serviceId })
    this.$store.dispatch('formHandler/setExternalIdentifierKey', query.externalIdentifierKey)
  },
  beforeDestroy () {
    // this.$store.dispatch('formHandler/resetForm')
  },
  computed: {
    ...mapGetters({
      formSchema: 'formHandler/formSchema',
      formErrors: 'formHandler/formErrors',
      formActions: 'formActions/formActions',
      formOptions: 'formHandler/formOptions'
    }),
    actionsLoaded () {
      return this.$store.state.appOnline
    }
  },
  components: {
    FormWidget,
    FormActionWidget
  }
}
</script>

<style>

</style>
