<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-select label="form picker" v-model="search.form" clearable :items="forms" item-text="name">
          </v-select>
        </v-col>
      </v-row>
      <v-text-field label="employee id" v-model="search.employeeId" clearable placeholder="Search employeeId.."/>
      <v-text-field label="text id" v-model="search.textId" clearable placeholder="Search textId.."/>
      <v-text-field label="NDIS number" v-model="search.ndisNumber" clearable placeholder="Search NDISNumber.."/>

      <date-range :fromDate = this.startDate v-on:change-date="onChangeDate"></date-range>

      <v-row>
        <v-col>
          <v-btn class = "primary" :loading="searching || initialising"
            @click="clickGetSubmissions()">
            Fetch submissions
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div>
       <v-data-table
        :headers="headers"
        :items="submissionList"
        :options.sync="options"
        :server-items-length="totalSubmissions"
        :loading="searching"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10]
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn :href="item.url" target="_blank" small :disabled="searching" color="primary">Open</v-btn>
        </template>
        <template v-slot:no-data>
          <v-alert :value="fetchError.length > 0" color="error" icon="warning">
            {{fetchError}}
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </section>
</template>

<script>

import searchApi from '@/api/searchApi'
import { dateSettings } from '@/constants.js'
import { mapGetters } from 'vuex'
import DateRange from '@/components/shared/DateRange'

class Search {
  constructor (form, employeeId, textId, ndisNumber, startDate, finishDate) {
    this.form = form
    this.employeeId = employeeId
    this.textId = textId
    this.ndisNumber = ndisNumber
    this.startDate = startDate
    this.finishDate = finishDate
  }
}
class SubmissionResult {
  constructor (name, url, identifierValue, created) {
    this.url = url
    this.name = name
    this.identifierValue = identifierValue
    this.created = created
  }
}
export default {
  name: 'SearchSubmissions',
  data: () => ({
    search: new Search('', ''),
    initialising: false,
    searching: false,
    fetchError: '',
    submissionList: [],
    totalSubmissions: 0,
    forms: [],
    searchButtonClicked: false,
    menuStart: false,
    menuFinish: false,
    startDate: null,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created'],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    },
    headers: [
      {
        text: 'Form name',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Key', value: 'identifierValue' },
      { text: 'Created', value: 'created', type: 'date' },
      { text: 'Action', value: 'action', align: 'end' }
    ]
  }),
  async beforeMount () {
    this.initialise()
  },
  mounted () {
  },
  methods: {
    async clickGetSubmissions () {
      this.searchButtonClicked = true
      this.options.page = 1
      await this.getSubmissions()
      this.searchButtonClicked = false
    },
    async initialise () {
      try {
        this.initialising = true
        var ret = await searchApi.getSchemaHistory()
        this.forms = ret.data
      } catch (error) {
        this.fetchError = error.message
      } finally {
        this.initialising = false
      }
    },
    async getSubmissions () {
      this.fetchError = ''
      if (!this.forms.length > 0) {
        await this.initialise()
        return
      }
      this.searching = true
      this.submissionList = []
      try {
        var ret = await searchApi.getSubmissions(this.search, this.options)
        this.totalSubmissions = ret.data.total
        ret.data.data.items.forEach(async x => {
          var form = await this.findSchema(x.formId)
          var formName = 'not found'
          if (form) {
            formName = form.name
          }
          this.submissionList.push(new SubmissionResult(formName,
          `${window.location.origin}/#/forms/complete/${x.submissionId}`,
          x.identifierValue,
          `${dateSettings.dateTimeFormat(x.created)}`))
        })
      } catch (error) {
        this.fetchError = error.message
      } finally {
        this.searching = false
      }
    },
    async findSchema (schemaId) {
      return this.forms.find(({ _id }) => _id === schemaId)
    },
    onChangeDate: function (event) {
      this.search.startDate = event.startDate
      this.search.finishDate = event.finishDate
    }
  },
  computed: {
    ...mapGetters({
    }),
    getFetchError () {
      return ''
    }
  },
  watch: {
    options: {
      handler () {
        if (!this.searchButtonClicked && !this.initialising) {
          this.getSubmissions()
        }
      },
      deep: true
    }
  },
  components: {
    DateRange
  }
}
</script>
