<template>
  <section>
    <v-row>
      <v-col align-self="center">
        <h5 class="pa-3">
          <v-icon>description</v-icon> Form Designer
        </h5>
      </v-col>
      <v-col align-self="center" class="text-sm-right">
        <v-btn color="primary" router-link to="/schema/add" class="mr-3">Add New Schema</v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-data-table
    :headers="headers"
    :items="formItems"
    :items-per-page="10"
    :loading="loading"
    :search="search"
    :custom-filter="searchFilter"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    class="elevation-1 schema-list-table"
  >
    <template v-slot:top>
        <v-text-field ref="searchBox"
          v-model="search"
          label="Search (lower case only)"
          class="mx-4"
          color="searchText"
          clearable
          dense
        ></v-text-field>
    </template>
    <template v-slot:[`item.created`]="{ item }">
      <span>{{`${dateSettings.dateTimeFormat(item.created)}`}}</span>
    </template>
    <template v-slot:[`item.lastModified`]="{ item }">
      <span>{{`${dateSettings.dateTimeFormat(item.lastModified)}`}}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mr-6 mx-auto"
              small color="primary"
              router-link :to="{path: `/schema/copy/${item._id}`}" dark>
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
            </template>
          <span>Copy schema</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
          <v-btn small
            v-bind="attrs"
            v-on="on"
            color="primary"
            router-link :to="{path: `/schema/edit/${item._id}`}" dark>
            <v-icon>mdi-account-edit-outline</v-icon>
          </v-btn>
          </template>
          <span>Edit schema</span>
        </v-tooltip>
    </template>
  </v-data-table>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import { dateSettings } from '@/constants.js'
export default {
  name: 'FormSchemaList',
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'Form name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      { text: 'Path', value: 'path' },
      { text: 'Version', value: 'version' },
      { text: 'Created', value: 'created' },
      { text: 'Modified', value: 'lastModified' },
      { text: '', value: 'action', align: 'end' }
    ],
    sortBy: 'lastModified',
    sortDesc: true,
    search: ''
  }),
  mounted () {
    this.clearSearch()
  },
  methods: {
    moment: function (date) {
      return moment(date)
    },
    searchFilter (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
    },
    clearSearch () {
      this.search = ''
      this.focusSearch()
    },
    focusSearch () {
      this.$refs.searchBox.focus()
    }
  },
  computed: {
    ...mapGetters({
      formItems: 'formList/formItems'
    }),
    dateSettings () {
      return dateSettings
    }
  }
}
</script>

<style>
  .schema-list-table td.text-end  {
  white-space: nowrap;
}
</style>
