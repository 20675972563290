<template>
  <section>
    <form-action-widget :actions="formActions" :submissionMode="false"></form-action-widget>
    <form-widget :formSchemaData="formSchema" :formOptions="formOptions" :storeIncompleteSubmission="false" :showClearButton="false"></form-widget>
  </section>
</template>

<script>
import FormWidget from '@/components/forms/FormWidget'
import FormActionWidget from '@/components/forms/FormActionWidget'
import { mapGetters } from 'vuex'
export default {
  name: 'FormsSubmission',
  mounted () {
    this.$store.dispatch('formHandler/setOptions', { readOnly: true })
    this.$store.dispatch('formHandler/getStoredSchemas')
  },
  beforeDestroy () {
  },
  computed: {
    ...mapGetters({
      formSchema: 'formHandler/formSchema',
      formActions: 'formActions/formActions',
      formOptions: 'formHandler/formOptions'
    })
  },
  components: {
    FormWidget,
    FormActionWidget
  }
}
</script>

<style>

</style>
