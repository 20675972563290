import { dataHelpers } from '@/store/helpers'
const state = {
  title: 'Notifications',
  snackbar: {
    mode: null,
    closeBtn: true,
    color: 'primary',
    timeout: 0,
    text: '',
    loader: false,
    visible: false,
    action: '',
    actionText: '',
    align: 'left'
  }
}
const getters = {
  snackbar: state => state.snackbar
}

const actions = {
  showLoader ({ commit }, data) {
    const loadtext = data.text ? data.text : 'Please wait'
    commit('setSnackbarState', { text: loadtext, visible: true, closeBtn: false, loader: true })
  },
  hideLoader ({ commit }, data) {
    commit('setSnackbarState', { visible: false })
  },
  showSuccess ({ commit }, data) {
    const successtext = data.text || 'Success'
    const successtimeout = data.timeout || 20000
    const closeButton = dataHelpers.nullishOperator(data.closeBtn, true)
    commit('setSnackbarState', { text: successtext, visible: true, closeBtn: closeButton, color: 'success', timeout: successtimeout, loader: false, align: data.align })
  },
  showAction ({ commit }, data) {
    const actionText = data.text || 'Action required'
    var closeButton = data.closeBtn
    if (closeButton === 'undefined') {
      closeButton = true
    }
    commit('setSnackbarState', { text: actionText, visible: true, closeBtn: closeButton, color: 'lime darken-2', loader: false, action: data.action, actionText: data.actionText })
  },
  hideSuccess ({ commit }, data) {
  },
  showError ({ commit }, data) {
    const errortext = data.text ? data.text : 'Error'
    commit('setSnackbarState', { text: errortext, visible: true, closeBtn: true, color: 'error' })
  },
  hideError ({ commit }, data) {
  },
  showInfo ({ commit }, data) {
  },
  hideInfo ({ commit }, data) {
  },
  close ({ commit }) {
    commit('close')
  }
}

const mutations = {
  setSnackbarState (state, data) {
    state.snackbar.visible = data.visible
    state.snackbar.text = data.text
    state.snackbar.closeBtn = data.closeBtn
    state.snackbar.color = data.color
    state.snackbar.loader = data.loader
    if (data.timeout && data.timeout > 0) {
      setTimeout(function () {
        state.snackbar.visible = false
      }, data.timeout)
    }
    if (data.action) {
      state.snackbar.action = data.action
    }
    if (data.align) {
      state.snackbar.align = data.align
    }
    state.snackbar.actionText = data.actionText
  },
  close (state) {
    state.snackbar.visible = false
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
