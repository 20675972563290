<template>
  <section>
    <schema-builder></schema-builder>
  </section>
</template>

<script>
import SchemaBuilder from '@/components/schema/SchemaBuilder'
export default {
  name: 'SchemaAdd',
  mounted () {
    this.$store.dispatch('schemaBuilder/setExisting', false)
  },
  beforeDestroy () {
  },
  computed: {
  },
  components: {
    SchemaBuilder
  }
}
</script>

<style>

</style>
