<template>
  <section>
  <div>
    <v-row class="px-3">
      <v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            label="path"
            v-model="schema.path"
            outlined
            hide-details
            readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <span>Path property is derived from the form name when the form is first created</span>
        </v-tooltip>
      </v-col>
      <v-col>
        <v-text-field
          label="version"
          v-model="schema.version"
            outlined
            hide-details
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-3">
        <v-col>
          <v-text-field
            label="created"
            v-model="schema.created"
            outlined
            hide-details
            readonly
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="last modified"
            v-model="schema.lastModified"
            outlined
            hide-details
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  name: 'SchemaMetadata',
  data: () => ({
  }),
  beforeMount () {
  },
  methods: {
  },
  computed: {
    ...mapFields([
    ]),
    ...mapGetters({
    }),
    schema () {
      return this.$store.state.schemaBuilder.schema
    }
  },
  components: {
  }
}
</script>
