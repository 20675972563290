<template>
  <section>
    <v-alert
      type="info"
      v-show="!prefillData.serverCheckComplete" >
      <v-row align="center">
        <v-col class="grow">
          checking for stale data...
        </v-col>
      </v-row>
       <v-overlay absolute="absolute" :value="!this.prefillData.serverCheckComplete" >
        <v-progress-circular v-if="!this.prefillData.serverCheckComplete" size="64" indeterminate color="primary"></v-progress-circular>
      </v-overlay>
    </v-alert>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      v-show="!prefillData.isValid && prefillData.serverCheckComplete && prefillData.errorList.length === 0"
      type="warning">
      <v-row align="center">
        <v-col class="grow">
          Data may be out of date...
        </v-col>
        <v-col class="shrink">
          <v-btn @click="validateData">Check data</v-btn>
        </v-col>
      </v-row>
    </v-alert>
     <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      v-show="prefillData.errorList.length > 0"
      type="error">
      <v-row align="center">
        <v-col class="grow">
          The data on this form has changed! Please clear the form and start again.
          <p>{{prefillData.errorList}}</p>
        </v-col>
      </v-row>
    </v-alert>
    <form-action-widget :actions="formActions"></form-action-widget>
    <form-widget :formSchemaData="formSchema" :formOptions="formOptions" :storeIncompleteSubmission="true"></form-widget>
  </section>
</template>
<script>
import FormWidget from '@/components/forms/FormWidget'
import FormActionWidget from '@/components/forms/FormActionWidget'
import { mapGetters } from 'vuex'
import { dataHelpers } from '@/store/helpers'
import router from '@/router'
export default {
  name: 'FormsResume',
  async mounted () {
    const params = this.$route.params
    const query = this.$route.query
    var userId = dataHelpers.nullishOperator(query.userId, window.localStorage.user_id)

    this.$store.dispatch('formHandler/resetForm')
    var compositeKey = dataHelpers.getIncompleteSubmissionKey(this.$route.params.id, this.$route.query)
    await this.$store.dispatch('formHandler/getIncompleteSubmission', { id: compositeKey })
    await this.$store.dispatch('formHandler/getStoredSchemas')
    this.$store.dispatch('formHandler/getFormSchema', { id: params.id, formLifeCycle: 0, userId: userId, serviceId: query.serviceId, childId: query.childId })
    this.$store.dispatch('formHandler/getPrefilledValidationData', { id: params.id, query: this.$route.query, userId: userId })
    this.$store.dispatch('formHandler/setExisting', false)
    this.$store.dispatch('formActions/getIncompleteActions', { id: compositeKey })
    this.$store.dispatch('formHandler/setExternalIdentifierKey', query.externalIdentifierKey)
  },
  beforeDestroy () {
    // this.$store.dispatch('formHandler/resetForm')
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    async validateData ({ rootState, dispatch }) {
      // this will force a validation check
      var addPath = router.history.current.fullPath.replace('/resume/', '/add/')
      router.replace(addPath)
    },
    showDialog () {
      this.dialog = true
    }
  },
  computed: {
    ...mapGetters({
      formSchema: 'formHandler/formSchema',
      formErrors: 'formHandler/formErrors',
      formActions: 'formActions/formActions',
      formOptions: 'formHandler/formOptions',
      formSubmission: 'formHandler/submission',
      prefillData: 'formHandler/prefillData'
    })
  },
  components: {
    FormWidget,
    FormActionWidget
  }
}
</script>

<style>
/* .v-dialog {
    position: absolute;
    bottom: 0;
    right: 20;
    top: 0;
    left:0;
    width:280px;
    height: 175px;
} */
</style>
