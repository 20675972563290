<template>
  <section>
    <div id="builderDiv"></div>
  </section>
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import { Formio } from 'formiojs'
export default {
  name: 'BuilderTest',
  props: ['form', 'options'],
  data: () => ({
  }),
  beforeMount () {
  },
  mounted () {
    var instance = this
    var builderReady = Formio.builder(document.getElementById('builderDiv'), this.form, this.options)
    builderReady.then(function (builder) {
      builder.on('saveComponent', function () {
        builder.events.onAny(args => {
          const eventParts = args.split('.')
          const namespace = 'formio'
          if (eventParts[0] !== namespace || eventParts.length !== 2) {
            return
          }
          // Emit a change event if the schema changes.
          if (['change', 'saveComponent', 'updateComponent', 'deleteComponent'].includes(eventParts[1])) {
            instance.$emit('change', builder.schema)
          }
        })
      })
      builder.on('change', form => {
        // form is current JS object
        // console.log('schema', form)
      })
    })
  },
  methods: {
  },
  computed: {
    ...mapFields([
      'schemaBuilder.schema.settings'
    ]),
    ...mapGetters({
      actionTriggers: 'formActions/actionTriggers',
      actionTriggersFull: 'formActions/actionTriggersFull'
    }),
    showLoading () {
      return this.$store.state.schemaBuilder.loading || this.$store.state.schemaBuilder.loadingSchema
    }
  },
  components: {
  }
}
</script>
