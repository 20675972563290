<template>
  <section>
    <v-overlay :value="showLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <h1 class="px-3 pb-3">
      <v-icon>description</v-icon>Form Builder
    </h1>
    <v-divider></v-divider>
    <v-row class="px-3">
      <v-col align-self="center">
        <v-text-field
            label="Form Schema Name"
            v-model="name"
            outlined
            hide-details
        ></v-text-field>
      </v-col>
      <v-col align-self="center">
        <v-btn color="success" @click="saveSchema">SAVE SCHEMA</v-btn>
      </v-col>
        <v-alert type = 'error'
          v-model="showErrorMessage"
          v-text="errorMessage">
        </v-alert>
    </v-row>
    <v-divider></v-divider>
    <div class="ma-3">
      <v-card>
        <v-tabs color="black" slider-color="primary"  background-color="grey lighten-3" v-model="schemaTabs">
          <v-tab href="#schema-builder">Form Builder</v-tab>
          <v-tab href="#schema-settings">Form Settings</v-tab>
          <v-tab href="#schema-metadata">Form Metadata</v-tab>
          <v-tab href="#schema-type">Form Type</v-tab>
          <v-tab href="#schema-utils">Utils</v-tab>
        </v-tabs>
        <v-tabs-items v-model="schemaTabs">
          <v-tab-item value="schema-builder">
            <div v-if="!showLoading">
              <builder-test
                :form=schema
                :options="options"
                v-on:change="updateSchema"
            ></builder-test>
            </div>
          </v-tab-item>
           <v-tab-item value="schema-settings">
             <div>
                <v-row class="px-3">
                  <v-col>
                    <schema-properties></schema-properties>
                  </v-col>
                  <v-col>
                    <schema-actions
                    :settings = 'settings'
                    ></schema-actions>
                  </v-col>
                </v-row>
              </div>
              </v-tab-item>
              <v-tab-item value="schema-metadata">
                <div v-if="!loading">
                  <div>
                    <schema-metadata></schema-metadata>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item value="schema-type">
                <div v-if="!loading && this.formType">
                   <v-alert
                      dense
                      border="left"
                      type="warning"
                    >
                      changes to the form type reflect across all versions of forms using path '{{this.schema.path}}'
                    </v-alert>
                     <schema-actions
                    :settings = 'this.formType.settings'
                    ></schema-actions>
                </div>
                <v-btn class="float-right" color="success" @click="saveSchemaType">SAVE SCHEMA TYPE</v-btn>
              </v-tab-item>
              <v-tab-item value="schema-utils">
                <div v-if="!loading">
                  <div>
                    <schema-utils></schema-utils>
                  </div>
                </div>
              </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </section>
</template>

<script>
// import { FormBuilder } from 'vue-formio'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import SchemaActions from '@/components/schema/SchemaActions'
import SchemaProperties from '@/components/schema/SchemaProperties'
import SchemaMetadata from '@/components/schema/SchemaMetadata'
import BuilderTest from '@/components/schema/SchemaBuilderFormio'
import SchemaUtils from './SchemaUtils'
export default {
  name: 'SchemaBuilder',
  data: () => ({
    schemaTabs: null,
    errorMessage: null
  }),
  beforeMount () {
    this.$store.dispatch('schemaBuilder/resetBuilder')
    this.$store.dispatch('schemaBuilder/getCustomComponents')
  },
  mounted () {
  },
  methods: {
    updateSchema (schema, e) {
      if (schema.components && schema.components.length) {
        this.$store.dispatch('schemaBuilder/updateSchema', schema)
      }
    },
    saveSchema () {
      this.$store.dispatch('schemaBuilder/saveSchema')
    },
    saveSchemaType () {
      this.$store.dispatch('schemaBuilder/saveSchemaType')
    }
  },
  computed: {
    ...mapFields([
      'schemaBuilder.schema.name',
      'schemaBuilder.schema.settings'
    ]),
    ...mapGetters({
    }),
    loading () {
      return this.$store.state.schemaBuilder.loading
    },
    options () {
      return this.$store.state.schemaBuilder.options
    },
    schema () {
      return this.$store.state.schemaBuilder.schema
    },
    formType () {
      return this.$store.state.schemaBuilder.formType
    },
    showErrorMessage () {
      return this.errorMessage !== null
    },
    submissionError () {
      var error = this.$store.state.schemaBuilder.submissionError
      if (error) {
        if (error.data) {
          if (error.data.message) {
            return error.data.message
          }
          return error.data
        }
      }
      return null
    },
    showLoading () {
      return this.$store.state.schemaBuilder.loading || this.$store.state.schemaBuilder.loadingSchema
    }
  },
  watch: {
    submissionError (status) {
      this.errorMessage = this.submissionError
    }
  },
  components: {
    // FormBuilder,
    SchemaActions,
    SchemaProperties,
    SchemaMetadata,
    BuilderTest,
    SchemaUtils
  }
}
</script>
