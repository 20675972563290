/* eslint-disable */
/**
 * This file shows how to create a custom component.
 *
 * Get the base component class by referencing Formio.Components.components map.
 */

 function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

 function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

 function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
 
 function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
 
 function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
 
 function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

 function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
 var _lodash = _interopRequireDefault(require("lodash"));

import { Components } from 'formiojs';
// import { eachComponent, uniqueName } from 'formiojs/utils/utils'
import DataGridEditDisplay from './DataGrid.edit.display.js'
var DataGridComponent = Components.components.datagrid;
var editForm = DataGridComponent.editForm
/**
 * Here we will derive from the base component which all Form.io form components derive from.
 *
 * @param component
 * @param options
 * @param data
 * @constructor
 */
var DataGridExtended = /** @class */ (function (_super) {
    function DataGridExtended(component, options, data) {
        var _this = _super.call(this, component, options, data) || this;
        return _this;
    }                 

    DataGridComponent.prototype .hasHeader = function () {
      var _thisRows = this.rows;
      var hideEmptyGrid = this.component.hideEmptyGrid;
      return this.component.components.reduce(function (hasHeader, col) {
        // If any of the components has a title and it isn't hidden, display the header.        
        let result = hasHeader || (col.label || col.title) && !col.hideLabel && (!hideEmptyGrid && _thisRows.length>0);
        return result;
      }, false);
    }
    
    DataGridComponent.prototype.setValue = function (value) {
      var _this11 = this;

      var flags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      
      if (!value) {        
        this.dataValue = this.defaultValue;
        this.createRows();
        return false;
      }

      if (!Array.isArray(value)) {
        if (_typeof(value) === 'object') {
          value = [value];
        } else {
          this.createRows();
          value = [{}];
        }
      } // Make sure we always have at least one row.
      // NOTE: Removing this will break "Public Configurations" in portal. ;)

      if (value && !value.length && !this.initEmpty && !this.component.hideEmptyGrid) {
          value.push({});
      }

      var isSettingSubmission = flags.fromSubmission && !_lodash.default.isEqual(value, this.emptyValue);
      var changed = this.hasChanged(value, this.dataValue);
      this.dataValue = value;

      if (this.initRows || isSettingSubmission) {
        this.createRows();
      }

      if (this.componentModal && isSettingSubmission) {
        this.componentModal.setValue(value);
      }

      this.rows.forEach(function (row, rowIndex) {
        if (value.length <= rowIndex) {
          return;
        }

        _lodash.default.each(row, function (col) {
          col.rowIndex = rowIndex;

          _this11.setNestedValue(col, value[rowIndex], flags);
        });
      });
      this.updateOnChange(flags, changed);
      return changed;
    }
    // copied from formiojs source
    DataGridComponent.prototype.removeRow = function (index) {

      let doDelete=true
      if (this.component.confirmDelete) {
        doDelete = window.confirm("Sure you want to delete?")
        console.log('test', this.component.initEmpty, 
        this.component.initEmpty, this.emptyValue, this.dataValue, this.defaultValue);
      }
      if (doDelete) {        
        this.splice(index);

        var _this$rows$splice = this.rows.splice(index, 1),
            _this$rows$splice2 = _slicedToArray(_this$rows$splice, 1),
            row = _this$rows$splice2[0];

        this.removeRowComponents(row);
        this.updateRowsComponents(index);
        this.setValue(this.dataValue, {
          isReordered: true
        });                
        this.redraw();        
      } 
    }

    DataGridComponent.editForm = function(...extend) {      
      return editForm([{
        label: "Row",
        key: "rowRules",
        weight: 8,
        components: DataGridEditDisplay
      },
      {
        key:"display"
      }
    ], ...extend)};
  
    return DataGridExtended;
}(DataGridComponent));
 export default DataGridExtended; 

