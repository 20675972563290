<template>
  <section>
    <h5 class="pa-3">
      <v-icon>description</v-icon> Submit a new form
    </h5>
    <v-divider></v-divider>
    <v-data-table
    :headers="headers"
    :items="formItems"
    :items-per-page="10"
    :loading="loading"
    :search="search"
    :custom-filter="searchFilter"
    :item-class="getItemClass"
    :sort-desc="sortDesc"
    :sort-by="sortBy"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-text-field ref="searchBox"
        v-model="search"
        label="Search (lower case only)"
        class="mx-4"
        color="searchText"
        clearable
      ></v-text-field>
    </template>
    <template v-slot:[`item.created`]="{ item }">
      <span>{{`${dateSettings.dateTimeFormat(item.created)}`}}
      </span>
    </template>
    <template v-slot:[`item.lastModified`]="{ item }">
      <span>{{`${dateSettings.dateTimeFormat(item.lastModified)}`}}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn small
        :disabled="!isOnline && !checkSchemas(item._id)"
        color="primary"
        router-link :to="{path: `/forms/add/${item._id}`}"
        >
        Create
      </v-btn>
    </template>
  </v-data-table>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'
import { dateSettings } from '@/constants.js'

export default {
  name: 'FormList',
  data: () => ({
    headers: [
      {
        text: 'Form name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      { text: 'Path', value: 'path' },
      { text: 'Version', value: 'version' },
      { text: 'Created', value: 'created', type: 'date' },
      { text: 'Modified', value: 'lastModified', type: 'date' },
      { text: '', value: 'action', align: 'end', sortable: false }
    ],
    sortBy: 'lastModified',
    sortDesc: true,
    search: ''
  }),
  mounted () {
    this.$store.dispatch('formList/getAvailableForms')
    this.$store.dispatch('formHandler/getStoredSchemas')
  },
  methods: {
    checkSchemas (id) {
      const hasSchema = find(this.allSchemas, { id: id })
      return hasSchema
    },
    getItemClass () {
      if (this.search && this.search.length > 0) {
        return 'searchText--text'
      }
      return ''
    },
    searchFilter (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
    },
    focusSearch () {
      this.$refs.searchBox.focus()
    }
  },
  computed: {
    ...mapGetters({
      formItems: 'formList/formItems'
    }),
    allSchemas () {
      return this.$store.state.formHandler.storedSchemas
    },
    isOnline () {
      return this.$store.state.appOnline
    },
    loading () {
      return this.$store.state.formList.loading
    },
    dateSettings () {
      return dateSettings
    }
  }
}
</script>

<style scoped>
.v-text-field{
      max-width: 50%;
}
</style>
