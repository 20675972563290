export const dataHelpers = {
  createId: (appender) => {
    return `${appender}${Math.random().toString(36).substr(2, 9)}`
  },
  getIncompleteSubmissionKey: (id, query) => {
    var keyVal = ''
    var keyArray = []
    // first create array - then sort for consistent key creation
    Object.keys(query).forEach(key => {
      var value = query[key]
      if (key !== 'externalIdentifierKey') {
        if (value && value.length > 0) {
          var val = `&${key}=${value}`
          keyArray.push(val)
        }
      }
    })
    keyArray.sort()
    keyArray.forEach(v => {
      keyVal += v
    })
    var finalVal = `${id}?${keyVal.substring(1, keyVal.length)}`
    return finalVal
  },
  nullishOperator (leftSide, rightSide) {
    if (leftSide !== null && leftSide !== 'undefined' && leftSide !== undefined) {
      return leftSide
    }
    return rightSide
  },
  containsPermissionInHash (aclHash, permissionId) {
    if (permissionId < 1 || aclHash == null) {
      return false
    }
    var byteArray = new Uint8Array(atob(aclHash).split('').map((c) => c.charCodeAt(0)))
    const bitArray = []
    byteArray.forEach(byte => {
      for (var j = 0; j <= 7; j++) {
        var b = ((byte >> j) & 0x1) > 0
        bitArray.push(b)
      }
    })
    if (bitArray.length < permissionId) {
      return false
    }
    return bitArray[permissionId - 1]
  }
}
export default {
  dataHelpers
}
