import { openDB } from 'idb'
// import { openDB } from 'idb/with-async-ittr.js'
const formsDB = {
  // name: `formsDatabase__${window.__config.NODE_ENV}`,
  name: function () {
    return `formsDatabase__${window.__config.NODE_ENV}`
  },
  version: 3
}
const tables = {
  unsubStoreName: 'unsubmitted',
  schemaStoreName: 'schemas',
  formsStoreName: 'activeForms',
  incompleteName: 'incomplete',
  fileStoreName: 'files'
}

const dbPromise = () => {
  const db = openDB(formsDB.name(), formsDB.version, {
    upgrade (db, oldversion, newversion) {
      if (oldversion < 1) {
        // INITIAL VERSION
        const store1 = db.createObjectStore(tables.unsubStoreName, {
          keyPath: 'id',
          autoIncrement: true
        })
        const store2 = db.createObjectStore(tables.schemaStoreName, {
          keyPath: 'id',
          autoIncrement: true
        })
        const store3 = db.createObjectStore(tables.formsStoreName, {
          keyPath: 'id',
          autoIncrement: true
        })
        store1.createIndex('id', 'id', { unique: true })
        store2.createIndex('id', 'id', { unique: true })
        store3.createIndex('name', 'name')
      }
      if (oldversion < 2) {
        // UPGRADE VERSION FROM 1 -> 2
        const store4 = db.createObjectStore(tables.incompleteName, {
          keyPath: 'id',
          autoIncrement: true
        })
        store4.createIndex('id', 'id', { unique: true })
      }
      if (oldversion < 3) {
        const store5 = db.createObjectStore(tables.fileStoreName, {
          keyPath: 'id',
          autoIncrement: true
        })
        store5.createIndex('id', 'id', { unique: true })
      }
      if (oldversion < 4) {
      }
      if (oldversion < 5) {
        // UPGRADE VERSION FROM 4 -> 5
      }
    }
  })
  return db
}

// /////////////////////////////////////////////////////////////////////////////////////
// Transactions for incomplete forms
// /////////////////////////////////////////////////////////////////////////////////////

const storeIncompleteSubmissionToStorage = async (formId, submission, formActions, id, expiryDate, creationDate, storeRemote, prefillData) => {
  const db = await dbPromise()
  var date = new Date()
  if (creationDate && creationDate !== 'undefined') {
    date = creationDate
  }
  const value = db.put(tables.incompleteName, {
    formId: formId,
    submission: submission,
    date: date,
    id: id,
    formActions: formActions,
    expiryDate: expiryDate,
    storeRemote: storeRemote,
    prefillData: prefillData
  })
  return value
}
const getAllStoredIncompletes = async () => {
  const db = await dbPromise()
  const tx = db.transaction(tables.incompleteName, 'readonly')
  const store = tx.objectStore(tables.incompleteName)
  const value = store.getAll()
  return value
}

const getStoredIncomplete = async (id) => {
  const db = await dbPromise()
  const tx = db.transaction(tables.incompleteName, 'readonly')
  const store = tx.objectStore(tables.incompleteName)
  const value = store.get(id)
  return value
}

const removeIncompleteFromStorage = async (id) => {
  const db = await dbPromise()
  const tx = db.transaction(tables.incompleteName, 'readwrite')
  const store = tx.objectStore(tables.incompleteName)
  store.delete(id)
}

const getStoredIncompleteBySubmissionId = async (submissionId) => {
  const all = await getAllStoredIncompletes()
  var value = null
  all.some(element => {
    if (element.submission._id === submissionId) {
      value = element
      return true
    }
  })
  return value
}
// /////////////////////////////////////////////////////////////////////////////////////
// Transactions for unsubmitted forms
// /////////////////////////////////////////////////////////////////////////////////////

const saveSubmissionToStorage = async (method, endpoint, formId, payload, id, auth) => {
  const db = await dbPromise()
  const value = db.put(tables.unsubStoreName, {
    method: method,
    endpoint: endpoint,
    formId: formId,
    submission: payload,
    date: new Date(),
    id: id,
    auth: auth
  })
  return value
}

const getAllStoredSubmits = async () => {
  const db = await dbPromise()
  const tx = db.transaction(tables.unsubStoreName, 'readonly')
  const store = tx.objectStore(tables.unsubStoreName)
  const value = store.getAll()
  return value
}

const getStoredSubmit = async (id) => {
  const db = await dbPromise()
  const tx = db.transaction(tables.unsubStoreName, 'readonly')
  const store = tx.objectStore(tables.unsubStoreName)
  const value = store.get(id)
  return value
}

const removeSubmitFromStorage = async (id) => {
  const db = await dbPromise()
  const tx = db.transaction(tables.unsubStoreName, 'readwrite')
  const store = tx.objectStore(tables.unsubStoreName)
  store.delete(id)
}

// /////////////////////////////////////////////////////////////////////////////////////
// Transactions for form schemas
// /////////////////////////////////////////////////////////////////////////////////////

const saveSchemaToStorage = async (schema) => {
  const db = await dbPromise()
  // remove any functions attached to schema
  const stringifiedSchema = JSON.stringify(schema)
  const rehydratedSchema = JSON.parse(stringifiedSchema)

  const value = db.put(tables.schemaStoreName, {
    schema: rehydratedSchema,
    id: schema._id,
    date: new Date()
  })
  return value
}

const getStoredSchema = async (id) => {
  const db = await dbPromise()
  const tx = db.transaction(tables.schemaStoreName, 'readonly')
  const store = tx.objectStore(tables.schemaStoreName)
  const value = store.get(id)
  return value
}

const getAllStoredSchemas = async () => {
  const db = await dbPromise()
  const tx = db.transaction(tables.schemaStoreName, 'readonly')
  const store = tx.objectStore(tables.schemaStoreName)
  const value = store.getAll()
  return value
}

// /////////////////////////////////////////////////////////////////////////////////////
// Transactions for available forms
// /////////////////////////////////////////////////////////////////////////////////////

const saveFormListToStorage = async (list) => {
  const db = await dbPromise()
  const value = db.put(tables.formsStoreName, {
    forms: list,
    id: 'availableForms',
    date: new Date()
  })
  return value
}

const getStoredFormsList = async (id) => {
  const db = await dbPromise()
  const tx = db.transaction(tables.formsStoreName, 'readonly')
  const store = tx.objectStore(tables.formsStoreName)
  const value = store.get('availableForms')
  return value
}

// /////////////////////////////////////////////////////////////////////////////////////
// Transactions for stored files
// /////////////////////////////////////////////////////////////////////////////////////

const saveFileToStorage = async (id, data, expiryDate) => {
  const db = await dbPromise()
  const value = db.put(tables.fileStoreName, {
    id: id,
    data: data,
    date: new Date(),
    expiryDate: expiryDate
  })
  return value
}

const getFileFromStorage = async (id) => {
  // evict expired files
  await deleteExpiredFiles()
  const db = await dbPromise()
  const tx = db.transaction(tables.fileStoreName, 'readonly')
  const store = tx.objectStore(tables.fileStoreName)
  const value = store.get(id)
  return value
}

const deleteExpiredFiles = async () => {
  var files = await getAllStoredFiles()
  for (const file of files) {
    if (file.expiryDate < new Date()) {
      await removeFileFromStorage(file.id)
    }
  }
}

const getAllStoredFiles = async () => {
  const db = await dbPromise()
  const tx = db.transaction(tables.fileStoreName, 'readonly')
  const store = tx.objectStore(tables.fileStoreName)
  const value = store.getAll()
  return value
}

const removeFileFromStorage = async (id) => {
  const db = await dbPromise()
  const tx = db.transaction(tables.fileStoreName, 'readwrite')
  const store = tx.objectStore(tables.fileStoreName)
  store.delete(id)
}
export default {
  saveSubmissionToStorage,
  storeIncompleteSubmissionToStorage,
  getStoredIncompleteBySubmissionId,
  getAllStoredSubmits,
  getAllStoredIncompletes,
  getStoredSubmit,
  removeSubmitFromStorage,
  removeIncompleteFromStorage,
  saveSchemaToStorage,
  getStoredSchema,
  getStoredIncomplete,
  getAllStoredSchemas,
  saveFormListToStorage,
  getStoredFormsList,
  saveFileToStorage,
  getFileFromStorage,
  removeFileFromStorage
}
