<template>
  <section>
    <search-submissions></search-submissions>
  </section>
</template>

<script>
import SearchSubmissions from '@/components/search/SearchSubmissions'
export default {
  name: 'Search',
  mounted () {
  },
  beforeDestroy () {
  },
  computed: {
  },
  components: {
    SearchSubmissions
  }
}
</script>

<style>

</style>
