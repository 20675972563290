import idbs from '@/api/indexedDBService'
import _ from 'lodash'
import router from '@/router'
import authHeaders from '@/api/authHeaders'
const state = {
  title: 'indexedDb',
  unsubmittedForms: []
}

const getters = {
}

const actions = {
  async storeFormLocal ({ commit, rootState, dispatch }, { formId, submission, formActions, id, expiryDate, storeRemote, prefillData }) {
    var storedIncomplete = await idbs.getStoredIncomplete(id)
    var creationDate = new Date()
    if (storedIncomplete) {
      creationDate = storedIncomplete.date
      if (!storeRemote) {
        storeRemote = storedIncomplete.storeRemote
      }
    }
    idbs.storeIncompleteSubmissionToStorage(formId, submission, formActions, id, expiryDate, creationDate, storeRemote, prefillData).then((res) => {
      // check if online here also
    }).then(() => {
      // dispatch('indexedDb/checkUnsubmits', null, { root: true })
    })
  },
  saveFormLocal ({ commit, rootState, dispatch }, { method, endpoint, formId, payload, id, isExisting }) {
    // get current auth to allow for background sync submissions
    var config = {
      headers: {}
    }
    authHeaders.setAuthorizationHeader(config)
    idbs.saveSubmissionToStorage(method, endpoint, formId, payload, id, config).then((res) => {
      // check if online here also
      if (rootState.appOnline) {
        dispatch('saveFormRemote', res)
      } else {
        // if offline go to the forms unique editing ID
        if (rootState.formHandler.isExisting) { return false }
        router.replace(`/forms/submission/offline/${res}`)
      }
    }).then(() => {
      // dispatch('indexedDb/checkUnsubmits', null, { root: true })
    })
  },
  saveFormRemote ({ dispatch }, index) {
    idbs.getStoredSubmit(index).then((res) => {
      dispatch('formHandler/submitFormRemote', { submission: res, redirect: true }, { root: true })
    })
  },
  saveSchemaLocal ({ commit, dispatch }, schema) {
    idbs.saveSchemaToStorage(schema)
    // .catch((error) => {
    // console.error(`Error in saving: ${error}`)
    // })
  },
  checkUnsubmits ({ commit, dispatch, rootState }) {
    idbs.getAllStoredSubmits().then((res) => {
      commit('QUEUE_UNSUBMITS', res)
    }).then(() => {
      if (rootState.appOnline) {
        dispatch('formHandler/processUnsubmittedForms', null, { root: true })
      }
    })
  },
  async clearItem ({ commit, dispatch }, submission) {
    // dispatch function to indexedDBservice to also delete item from indexedDB
    idbs.removeSubmitFromStorage(submission.id)

    var findBySubmissionId = await idbs.getStoredIncompleteBySubmissionId(submission.id)

    if (findBySubmissionId) {
      dispatch('incompleteSubmissions/deleteIncompleteSubmission', findBySubmissionId.id, { root: true })
    }
    commit('REMOVE_UNSUBMITTED_ITEM', submission.id)
  }
}

const mutations = {
  QUEUE_UNSUBMITS (state, unsubs) {
    state.unsubmittedForms = unsubs
  },
  REMOVE_UNSUBMITTED_ITEM (state, id) {
    // find item in state that has matching id, then delete it form state
    const filteredData = _.reject(state.unsubmittedForms, { id: id })
    state.unsubmittedForms = filteredData
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
