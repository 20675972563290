import * as moment from 'moment'

var dateTimeFormat = 'ddd, MMM Do YYYY, HH:mm:ss'
export const dateSettings = {
  dateTimeFormat: function (date) {
    return moment(date).format(`${dateTimeFormat}`)
  }
}
export default {
  dateSettings
}
