<template>
  <section>
    <v-container fluid>
      <v-row  class="mx-4">
         <v-col>
          <v-text-field
          v-model="submissionId"
              label="paste the submissionId here"
              outlined>
          </v-text-field>
        </v-col>
         <v-col>
          <v-autocomplete
            v-model="member"
            :loading="initialising"
            :items="members"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Team members"
            item-text="preferredFullName"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row  class="mx-4">
         <v-col>
          <v-btn class = "primary" :loading="initialising"
            :disabled = "buttonDisabled"
            @click="giveAccess()">
            Give access
          </v-btn>
        </v-col>
      </v-row>
     </v-container>
  </section>
</template>

<script>
import searchApi from '@/api/searchApi'
export default {
  name: 'GenerateLink',
  data: () => ({
    members: [],
    member: null,
    submissionId: null,
    initialising: true
  }),
  async beforeMount () {
    this.initialise()
  },
  mounted () {
  },
  methods: {
    async giveAccess () {
      await searchApi.addClientAccess(this.member.teamMemberId, this.submissionId)
      this.$store.dispatch('notifications/showSuccess', { text: 'access given' }, { root: true })
    },
    async initialise () {
      try {
        this.initialising = true
        const [members] = await Promise.all([searchApi.getTeamMembers()])
        this.members = members.data
      } catch (error) {
        this.fetchError = error.message
      } finally {
        this.initialising = false
      }
    }
  },
  beforeDestroy () {
  },
  computed: {
    buttonDisabled () {
      return this.member === null
    }
  },
  components: {
  }
}
</script>

<style>

</style>
