<template>
  <div class="pa-3">
    <h3> Server details</h3>
    <v-card v-if="ready">
      <v-card-title>Sync-id</v-card-title>
      <v-card-text>{{getSyncId}}</v-card-text>
      <v-card-title>Environment</v-card-title>
      <v-card-text>{{getEnvironment}}</v-card-text>
    </v-card>
  </div>
</template>
<script>
import idbs from '@/api/serviceWorkerIndexedDb'
export default {
  name: 'FormsAdd',
  data: () => ({
    ready: false,
    serverDetails: {}
  }),
  mounted () {
    this.getServerDetails().then(ret => {
      this.serverDetails = ret
      this.ready = true
    })
  },
  methods: {
    getServerDetails () {
      return idbs.getAllStoredServerDetails().then(ret => {
        return ret[ret.length - 1]
      })
    }
  },
  computed: {
    getSyncId () {
      return this.serverDetails.payload.syncId
    },
    getEnvironment () {
      return this.serverDetails.payload.env
    }
  }
}
</script>
