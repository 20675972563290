import { openDB } from 'idb'

const serviceWorkerDb = {
  name: function () {
    return 'serviceWorkerDatabase'
  },
  version: 2
}
const serviceWorkerTables = {
  serverDetails: 'serverDetails',
  subscriptionStoreName: 'subscription'
}
const dbServiceWorkerPromise = () => {
  const db = openDB(serviceWorkerDb.name(), serviceWorkerDb.version, {
    upgrade (db, oldversion, newversion) {
      if (oldversion < 1) {
        // INITIAL VERSION
        db.createObjectStore(serviceWorkerTables.serverDetails, {
          keyPath: 'id',
          autoIncrement: true
        })
      }
      if (oldversion < 2) {
        if (oldversion < 2) {
          db.createObjectStore(serviceWorkerTables.subscriptionStoreName, {
            keyPath: 'id',
            autoIncrement: true
          })
        }
      }
      if (oldversion < 3) {
        // UPGRADE VERSION FROM 2 -> 3
      }
    }
  })
  return db
}

// /////////////////////////////////////////////////////////////////////////////////////
// Transactions for server details
// /////////////////////////////////////////////////////////////////////////////////////

const storeServerDetails = async (baseUrl, payload) => {
  await removeAllStoredServerDetails()
  const db = await dbServiceWorkerPromise()
  const value = db.put(serviceWorkerTables.serverDetails, {
    baseUrl: baseUrl,
    payload: payload,
    date: new Date()
  })
  return value
}
const getAllStoredServerDetails = async () => {
  const db = await dbServiceWorkerPromise()
  const tx = db.transaction(serviceWorkerTables.serverDetails, 'readonly')
  const store = tx.objectStore(serviceWorkerTables.serverDetails)
  const value = store.getAll()
  return value
}

const removeAllStoredServerDetails = async () => {
  const db = await dbServiceWorkerPromise()
  const tx = db.transaction(serviceWorkerTables.serverDetails, 'readwrite')
  const store = tx.objectStore(serviceWorkerTables.serverDetails)
  store.clear()
}

// /////////////////////////////////////////////////////////////////////////////////////
// Transactions for subscription details
// /////////////////////////////////////////////////////////////////////////////////////
const storeSubscriptionDetails = async (syncId, payload) => {
  await removeAllStoredSubscriptionDetails()
  const db = await dbServiceWorkerPromise()
  const value = db.put(serviceWorkerTables.subscriptionStoreName, {
    syncId: syncId,
    payload: payload,
    date: new Date()
  })
  return value
}

const getAllStoredSubscriptionDetails = async () => {
  const db = await dbServiceWorkerPromise()
  const tx = db.transaction(serviceWorkerTables.subscriptionStoreName, 'readonly')
  const store = tx.objectStore(serviceWorkerTables.subscriptionStoreName)
  const value = store.getAll()
  return value
}

const removeAllStoredSubscriptionDetails = async () => {
  const db = await dbServiceWorkerPromise()
  const tx = db.transaction(serviceWorkerTables.subscriptionStoreName, 'readwrite')
  const store = tx.objectStore(serviceWorkerTables.subscriptionStoreName)
  store.clear()
}
export default {
  storeServerDetails,
  getAllStoredServerDetails,
  removeAllStoredServerDetails,
  storeSubscriptionDetails,
  getAllStoredSubscriptionDetails,
  removeAllStoredSubscriptionDetails
}
