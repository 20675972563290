<template>
  <section>
    <schema-builder></schema-builder>
  </section>
</template>

<script>
import SchemaBuilder from '@/components/schema/SchemaBuilder'
export default {
  name: 'SchemaCopy',
  mounted () {
    const params = this.$route.params
    this.$store.dispatch('schemaBuilder/fetchSchema', params.id)
    // alter name to_copy and remove id
    this.$store.dispatch('schemaBuilder/createCopy')
    this.$store.dispatch('schemaBuilder/setExisting', true)
  },
  beforeDestroy () {
  },
  computed: {
  },
  components: {
    SchemaBuilder
  }
}
</script>

<style>

</style>
