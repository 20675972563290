/* eslint-disable */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/**
 * This file shows how to create a custom component.
 *
 * Get the base component class by referencing Formio.Components.components map.
 */
import { Components } from 'formiojs';
import { eachComponent, uniqueName } from 'formiojs/utils/utils'
import FileEditDisplay from './File.edit.display.js'
var FileComponent = Components.components.file;
var editForm = FileComponent.editForm
/**
 * Here we will derive from the base component which all Form.io form components derive from.
 *
 * @param component
 * @param options
 * @param data
 * @constructor
 */
var FileExtended = /** @class */ (function (_super) {
    function FileExtended(component, options, data) {
        var _this = _super.call(this, component, options, data) || this;
        return _this;
    }   
       
    FileComponent.prototype.getVideoStream = function (constraints) {
        return navigator.mediaDevices.getUserMedia({
            video: __assign({ width: { min: 640, ideal: 1920 }, height: { min: 360, ideal: 1080 }, aspectRatio: { ideal: 16 / 9 }, facingMode: 'environment' }, constraints),
            audio: false,
        });
    };
    function calculateSize(img, maxWidth, maxHeight) {
      let width = img.width;
      let height = img.height;
    
      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }
      return [width, height];
    }

    function getCanvasBlob(canvas, mimeType, quality) {
      const MIME_TYPE = "image/jpeg";
      const QUALITY = 0.7;  
      return new Promise(function(resolve, reject) {
        canvas.toBlob(
          (blob) => {
            resolve(blob)
          },
          mimeType,
          quality
        )
      })
    }

    function addFiles(obj, files, file) {
      var fileUpload = obj.validateTotalMaxSize(files)
      if(fileUpload.status === 'error') {                
        obj.statuses.push(fileUpload); 
        obj.redraw()
          return
      }               
      // Only allow one upload if not multiple.
      if (!obj.component.multiple) {
        files = Array.prototype.slice.call(files, 0, 1);
      }
      if (obj.component.storage && files && files.length) {
        // files is not really an array and does not have a forEach method, so fake it.        
      const fileName = uniqueName(file.name, obj.component.fileNameTemplate, obj.evalContext());
      const fileUpload = {
        originalName: file.name,
        name: fileName,
        size: file.size,
        status: 'info',
        message: obj.t('Starting upload'),
      };

      // Check file pattern
      if (obj.component.filePattern && !obj.validatePattern(file, obj.component.filePattern)) {
        fileUpload.status = 'error';
        fileUpload.message = obj.t('File is the wrong type; it must be {{ pattern }}', {
          pattern: obj.component.filePattern,
        });
      }

      // Check file minimum size
      if (obj.component.fileMinSize && !obj.validateMinSize(file, obj.component.fileMinSize)) {
        fileUpload.status = 'error';
        fileUpload.message = obj.t('File is too small; it must be at least {{ size }}', {
          size: obj.component.fileMinSize,
        });
      }

      // Check file maximum size
      if (obj.component.fileMaxSize && !obj.validateMaxSize(file, obj.component.fileMaxSize)) {
        fileUpload.status = 'error';
        fileUpload.message = obj.t('File is too big; it must be at most {{ size }}', {
          size: obj.component.fileMaxSize,
        });
      }

      // Get a unique name for this file to keep file collisions from occurring.
      const dir = obj.interpolate(obj.component.dir || '');
      const { fileService } = obj;
      if (!fileService) {
        fileUpload.status = 'error';
        fileUpload.message = obj.t('File Service not provided.');
      }

      obj.statuses.push(fileUpload);
      obj.redraw();

      if (fileUpload.status !== 'error') {
        if (obj.component.privateDownload) {
          file.private = true;
        }
        const { storage, options = {} } = obj.component;
        const url = obj.interpolate(obj.component.url);
        let groupKey = null;
        let groupPermissions = null;

        //Iterate through form components to find group resource if one exists
        eachComponent(obj.currentForm.components, (element) => {
          if (element.component && (element.component.submissionAccess || element.component.defaultPermission)) {
            groupPermissions = !element.component.submissionAccess ? [
              {
                type: element.component.defaultPermission,
                roles: [],
              },
            ] : element.component.submissionAccess;

            groupPermissions.forEach((permission) => {
              groupKey = ['admin', 'write', 'create'].includes(permission.type) ? element.component.key : null;
            });
          }
        });

        const fileKey = obj.component.fileKey || 'file';
        const groupResourceId = groupKey ? obj.currentForm.submission.data[groupKey]._id : null;
        fileService.uploadFile(storage, file, fileName, dir, (evt) => {
          fileUpload.status = 'progress';
            fileUpload.progress = parseInt(100.0 * evt.loaded / evt.total);
            delete fileUpload.message;
            obj.redraw();
            }, url, options, fileKey, groupPermissions, groupResourceId)
              .then((fileInfo) => {
                const index = obj.statuses.indexOf(fileUpload);
                if (index !== -1) {
                  obj.statuses.splice(index, 1);
                }
                fileInfo.originalName = file.name;
                if (!obj.hasValue()) {
                  obj.dataValue = [];
                }
                obj.dataValue.push(fileInfo);
                obj.redraw();
                obj.triggerChange();
              })
              .catch((response) => {
                fileUpload.status = 'error';
                fileUpload.message = response;
                delete fileUpload.progress;
                obj.redraw();
              });
            }
        }       
    }
    // copied from formiojs source
    FileComponent.prototype.upload = function (files) {      
        const IMG_TYPES = ["image/jpeg", "image/png"]
        this.statuses=[]                            

        const MAX_WIDTH = this.component.resizeWidth ?? 800;
        const MAX_HEIGHT = this.component.resizeHeight ?? 600;
        const QUALITY = this.component.resizeQuality ?? 1.0; 
        
        var me = this

        Array.prototype.forEach.call(files, (file) => {              
          if(this.component.resizeImages && IMG_TYPES.includes(file.type)) {    
            //resize image                      
             
            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
              URL.revokeObjectURL(this.src);
              // Handle the failure properly
              console.log("Cannot load image");
            };
                              
            img.onload = function () {
              URL.revokeObjectURL(this.src);
              const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
              const canvas = document.createElement("canvas");
              canvas.width = newWidth;
              canvas.height = newHeight;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, newWidth, newHeight);

              var canvasBlob = getCanvasBlob(canvas, file.type, QUALITY);
              canvasBlob.then(function(blob) {              
                let resizedFile = new File([blob], file.name,  {type: file.type})
                addFiles(me, files, resizedFile)    
                console.log('resizing file', file.size, resizedFile.size)                            
              }, function(err) {
                console.log(err)
              });              
            };      
          } else {
            console.log('NOT resizing file', file)
            addFiles(me, files, file)
          }
        })                            
    }

    FileComponent.prototype.validateTotalMaxSize = function (files) {
        var fileUpload = {
            originalName: 'all files',
            name: 'all files',
            size: 0,
            status: '',
            message: ''
          };   
        var size = 0
        this.dataValue.forEach(element => {
            size += element.size
        })        
        Array.from(files).forEach(file => {
            size += file.size
        })
        var base64Size = (4 * size) / 3
        if (base64Size > 26214400) { // 25 mb
            fileUpload.status = 'error'
            fileUpload.message = this.t('Combined size of Files is too big; they must be at most {{ size }} Mb', {
                size: 25,
            });                               
        }
        return fileUpload;
    }

    FileComponent.editForm = function(...extend) {      
      return editForm([{
        label: "Resize",
        key: "resizing",
        weight: 8,
        components: FileEditDisplay
      },
      {
        key:"display"
      }
    ], ...extend)};
  
    return FileExtended;
}(FileComponent));
 export default FileExtended; 

