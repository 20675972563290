var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',{attrs:{"align-self":"center"}},[_c('h5',{staticClass:"pa-3"},[_c('v-icon',[_vm._v("description")]),_vm._v(" Form Designer ")],1)]),_c('v-col',{staticClass:"text-sm-right",attrs:{"align-self":"center"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","router-link":"","to":"/schema/add"}},[_vm._v("Add New Schema")])],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1 schema-list-table",attrs:{"headers":_vm.headers,"items":_vm.formItems,"items-per-page":10,"loading":_vm.loading,"search":_vm.search,"custom-filter":_vm.searchFilter,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{ref:"searchBox",staticClass:"mx-4",attrs:{"label":"Search (lower case only)","color":"searchText","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("" + (_vm.dateSettings.dateTimeFormat(item.created)))))])]}},{key:"item.lastModified",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("" + (_vm.dateSettings.dateTimeFormat(item.lastModified)))))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-6 mx-auto",attrs:{"small":"","color":"primary","router-link":"","to":{path: ("/schema/copy/" + (item._id))},"dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy schema")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","router-link":"","to":{path: ("/schema/edit/" + (item._id))},"dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit schema")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }