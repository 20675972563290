/* eslint-disable no-console */
import { register } from 'register-service-worker'
import NotificationsApi from '@/api/notificationsApi'
import store from './store'
import idbs from '@/api/serviceWorkerIndexedDb'
let swRegistration
const applicationServerPublicKey = 'BGMt479vE735pSE5od44zSHXvW126QBff2ni6HPBzEtAN77YgVRcnM3V9OauhViWsjrrte2dXh-7XoU24lNw-Eg'
// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the subscription option
const urlB64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  /* eslint-disable no-useless-escape */
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
  /* eslint-enable no-useless-escape */
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
function getSubscription () {
  return swRegistration.pushManager.getSubscription().then(function (subscription) {
    return subscription
  })
}

const getServerDetails = async () => {
  var details = await idbs.getAllStoredServerDetails()
  return details[details.length - 1]
}
const getStoredSubscriptionDetails = async () => {
  var details = await idbs.getAllStoredSubscriptionDetails()
  return details[details.length - 1]
}
const setEnvironment = async () => {
  var obj = {
    env: window.__config.NODE_ENV,
    apiUrl: window.__config.API_URL,
    syncId: window.localStorage.sync_id
  }
  if (obj.apiUrl && obj.apiUrl !== 'undefined' && obj.syncId && obj.syncId !== 'undefined') {
    await idbs.storeServerDetails(obj.apiUrl, obj)
  }
  if ('serviceWorker' in navigator) {
    subscribe()
  }
}
function subscribeToServer (subscription, syncId) {
  if (syncId) {
    const cloneSubscription = JSON.parse(JSON.stringify(subscription))
    cloneSubscription.syncId = syncId
    NotificationsApi.subscribe(JSON.stringify(cloneSubscription)).then(ret => {
      idbs.storeSubscriptionDetails(syncId, subscription.endpoint)
      console.log('subscribed to server.', subscription, ret)
    }).catch(err => {
      store.dispatch('notifications/showError', { text: `Failed to subscribe to server ${err}.` }, { root: true })
      console.log('Failed to subscribe to server: ', err)
    })
  } else {
    console.log('Failed to subscribe to server: No syncId set')
  }
}

function sendFailedToSubscribeToServer (syncId) {
  if (syncId) {
    NotificationsApi.failedToSubscribe(syncId)
  }
}

const subscribe = async () => {
  // check the source code to get the method below
  swRegistration.pushManager.getSubscription().then(async (subscription) => {
    var syncId = window.localStorage.sync_id
    var serverDetails = await getServerDetails()
    if (serverDetails) {
      syncId = serverDetails.payload.syncId
    }
    if (!subscription) {
      requestNotificationPermission()
      const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey)
      swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      })
        .then(subscription => {
          subscribeToServer(subscription, syncId)
          showLocalNotification('service worker', 'service worker subscribed', swRegistration)
        })
        .catch(err => {
          sendFailedToSubscribeToServer(syncId)
          // store.dispatch('notifications/showError', { text: `${'It appears push notifications are currently disabled. This prevents you from receiving documents for offline use. Please contact IT support.'}` }, { root: true })
          console.log('Failed to subscribe the user: ', err)
        })
    } else {
      var currentlySubscribedSyncId
      var currentlySubscribedEndpoint
      var currentlySubscribedDate
      var subscriptionDetails = await getStoredSubscriptionDetails()
      if (subscriptionDetails) {
        currentlySubscribedSyncId = subscriptionDetails.syncId
        currentlySubscribedEndpoint = subscriptionDetails.payload
        currentlySubscribedDate = subscriptionDetails.date
      } else {
        var d = new Date()
        currentlySubscribedDate = d.setDate(d.getDate() - 2) // 2 days ago
      }

      var sendToServer = currentlySubscribedSyncId !== syncId
      if (!sendToServer) {
        // check endpoints
        if (currentlySubscribedEndpoint) {
          sendToServer = currentlySubscribedEndpoint !== subscription.endpoint
        }
        if (!sendToServer) {
          // send if details older than a day (there is some evidence of syncIds gbeing out of sync)
          const now = new Date()
          const diffTime = Math.abs(now - currentlySubscribedDate)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
          sendToServer = diffDays > 1
        }
      }
      if (sendToServer) {
        subscribeToServer(subscription, syncId)
      }
    }
  })
}
const showLocalNotification = (title, body, reg) => {
  const options = {
    body
    // here you can add more properties like icon, image, vibrate, etc.
  }
  console.log('show local notification', options)
  reg.showNotification(title, options)
}
function unsubscribe () {
  swRegistration.pushManager.getSubscription()
    .then(function (subscription) {
      console.log('in unsubscribe', subscription)
      if (subscription) {
        return subscription.unsubscribe()
      }
    })
    .catch(function (error) {
      console.log('Error unsubscribing', error)
    })
}
const requestNotificationPermission = async () => {
  const permission = await window.Notification.requestPermission()
  // value of permission can be 'granted', 'default', 'denied'
  // granted: user has accepted the request
  // default: user has dismissed the notification permission popup by clicking on x
  // denied: user has denied the request.
  if (permission !== 'granted') {
    console.log('notifications disallowed', permission)
  } else {
    console.log('notifications allowed')
  }
  return permission
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (reg) {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB', reg)
      swRegistration = reg
      setEnvironment()
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh...')
      store.dispatch('notifications/showAction', { text: 'New content is available. Press REFRESH to update.', actionText: 'Refresh', action: 'refreshContent/refresh', closeBtn: false }, { root: true })
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
export default {
  getSubscription,
  subscribe,
  unsubscribe,
  requestNotificationPermission
}
