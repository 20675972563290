<template>
  <section>
  <div>
      <v-row class="px-3">
        <v-col>
          <h5>Utils</h5>
          <div class="text-left">
             <v-overlay absolute="absolute" :value="showLoading">
                <v-progress-circular  v-if="showLoading" indeterminate color="primary"></v-progress-circular>
              </v-overlay>
            <v-btn text color="primary" v-on:click="generateSQL">Generate SQL script</v-btn>
          </div>
          <v-row>
            <v-col>
              <v-text-field
              v-model="generatedSQL"
              label="paste the results of this into the Postgres environment you require"
              outlined
              hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>

import { mapGetters } from 'vuex'
import formsApi from '@/api/formsApi'
export default {
  name: 'SchemaUtils',
  data: () => ({
    generatedSQL: '',
    showLoading: false
  }),
  beforeMount () {
  },
  methods: {
    async generateSQL (e) {
      this.showLoading = true
      const schemaId = this.$store.state.schemaBuilder.schema._id
      const ret = await formsApi.getSchemaGeneratedSQL(schemaId)
      this.generatedSQL = ret.data
      this.showLoading = false
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  components: {
  }
}
</script>
